import React from "react";
import { Dropdown } from "react-bootstrap";
import Avatar1 from "../../../assets/images/xs/avatar1.jpg";
import Avatar3 from "../../../assets/images/xs/avatar3.jpg";
import Avatar4 from "../../../assets/images/xs/avatar4.jpg";
import Avatar6 from "../../../assets/images/xs/avatar6.jpg";
import Avatar7 from "../../../assets/images/xs/avatar7.jpg";


const NotificationDropDown = (props) => {
  const tabEvents = (e, id) => {
    e.preventDefault();
    document.getElementById("navLink1").classList.remove("active")
    document.getElementById("navLink2").classList.remove("active")
    document.getElementById("navLink3").classList.remove("active")
    document.getElementById("navLink" + id).classList.add("active")

    document.getElementById("Noti-tab-Message1").classList.remove("active")
    document.getElementById("Noti-tab-Message1").classList.add("show")
    document.getElementById("Noti-tab-Message2").classList.remove("active")
    document.getElementById("Noti-tab-Message2").classList.add("show")
    document.getElementById("Noti-tab-Message3").classList.remove("active")
    document.getElementById("Noti-tab-Message3").classList.add("show")
    document.getElementById("Noti-tab-Message" + id).classList.add("active")
    document.getElementById("Noti-tab-Message" + id).classList.add("show")
  }
  const { DropdownClass, as, DropdownMenuClass, DropdownToggleClass } = props;
  
  return (
    <Dropdown as={as ? as : "div"} className={DropdownClass ? DropdownClass : " notifications position-relative"} drop="down">
      <Dropdown.Toggle as="a" id="dropdown-basic" className={DropdownToggleClass ? DropdownToggleClass : "nav-link dropdown-toggle pulse"}><i className="fa fa-bell"></i><span className="pulse-ring"></span></Dropdown.Toggle>

      <Dropdown.Menu as="div" className={DropdownMenuClass ? DropdownMenuClass : "p-0"} >
        <div className="card border-0 w380">
          <div className="card-header border-0 p-3">
            <h5 className="mb-0 fw-light d-flex justify-content-between">
              <span>Notifications Center</span>
              <span className="badge text-muted">14</span>
            </h5>
            <ul className="nav nav-tabs mt-3 border-bottom-0" role="tablist">
              <li className="nav-item">
                <a className="nav-link fw-light ps-0 me-2 active" id="navLink1" href="#!" onClick={(e) => { tabEvents(e, 1) }} role="tab">Message</a>
              </li>
              <li className="nav-item">
                <a className="nav-link fw-light me-2" id="navLink2" href="#Noti-tab-Events" onClick={(e) => { tabEvents(e, 2) }} role="tab">Events</a>
              </li>
              <li className="nav-item">
                <a className="nav-link fw-light" id="navLink3" href="#Noti-tab-Logs" onClick={(e) => { tabEvents(e, 3) }} role="tab">Logs</a>
              </li>
            </ul>
          </div>
          <div className="tab-content card-body overflow-auto">
            <div className="tab-pane fade show active" id="Noti-tab-Message1" role="tabpanel">
              <ul className="list-unstyled list mb-0">
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar1} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Chris Fox</span> <small>2MIN</small></p>
                      <span className="text-muted">changed an issue from "In Progress" to <span className="badge bg-success">Review</span></span>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <div className="avatar rounded-circle no-thumbnail">RH</div>
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Robert Hammer</span> <small>13MIN</small></p>
                      <span className="text-muted">It is a long established fact that a reader will be distracted</span>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar3} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Orlando Lentz</span> <small>1HR</small></p>
                      <span className="text-muted">There are many variations of passages</span>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar4} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Barbara Kelly</span> <small>1DAY</small></p>
                      <span className="text-muted">Contrary to popular belief <span className="badge bg-danger">Code</span></span>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar4} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Robert Hammer</span> <small>13MIN</small></p>
                      <span className="text-muted">making it over 2000 years old</span>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar6} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Orlando Lentz</span> <small>1HR</small></p>
                      <span className="text-muted">There are many variations of passages</span>
                    </div>
                  </a>
                </li>
                <li className="py-2">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar7} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Rose Rivera</span> <small className="">1DAY</small></p>
                      <span className="text-muted">The generated Lorem Ipsum</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="Noti-tab-Message2" role="tabpanel">
              <ul className="list-unstyled list mb-0">
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-info-circle fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Campaign <strong className="text-primary">Holiday Sale</strong> is nearly reach budget limit.</p>
                      <small className="text-muted">10:00 AM Today</small>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-thumbs-up fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Your New Campaign <strong className="text-primary">Holiday Sale</strong> is approved.</p>
                      <small className="text-muted">11:30 AM Today</small>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-pie-chart fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Website visits from Twitter is <strong className="text-danger">27% higher</strong> than last week.</p>
                      <small className="text-muted">04:00 PM Today</small>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-warning fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted"><strong className="text-warning">Error</strong> on website analytics configurations</p>
                      <small className="text-muted">Yesterday</small>
                    </div>
                  </a>
                </li>
                <li className="py-2 mb-1 border-bottom">
                  <a href="#!" onClick={(e) => { e.preventDefault(); }} className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-thumbs-up fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Your New Campaign <strong className="text-primary">Holiday Sale</strong> is approved.</p>
                      <small className="text-muted">11:30 AM Today</small>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="Noti-tab-Message3" role="tabpanel">
              <h4>No Logs right now!</h4>
            </div>
          </div>
          <a className="card-footer text-center border-top-0" href="#!"> View all notifications</a>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NotificationDropDown;