import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm } from "react-hook-form";
import Loading from '../../Layouts/Loading';
import invoiceFooterService from '../../../actions/invoiceFooterService';

const InvoiceFooterModal = (props) => {
  const { isModal, handleCloseModal, title, row, handleUpdate } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})

  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      title: row?.title || '',
      text: row?.text || '',
      space: row?.space || 0,
      sort_num: row?.sort_num || 0,
    }
  })

  const onSubmit = async (data) => {
    setLoading(true)
    let res;
    if (Object.keys(row).length) {
      data.id = row?.id
      res = await invoiceFooterService.updateInvoiceFooter(data);
    } else {
      res = await invoiceFooterService.createInvoiceFooter(data);
    }
    if (res.success && res.result) handleUpdate(res.result)
    setResponse(res)
    setLoading(false)
  }

  return (
    <Modal show={isModal} onHide={handleCloseModal} centered>
      {
        loading
          ? <Loading />
          :
          <div>
            <Modal.Header>
              <Modal.Title>{t(title)}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {t('Submit is successed')}
                </div>
              }
              <Modal.Body>
                <div className='mb-3'>
                  <label>{t('Headline')}</label>
                  <div className='mb-2'>
                    <input type="text" className="form-control"
                      {...register("title", { required: `${t('Field is required')}` })}
                    />
                    <div className="text-danger">{errors?.title?.message}</div>
                  </div>
                </div>

                <div className='mb-3'>
                  <label>{t('Contents')}</label>
                  <textarea className="form-control" rows={3}
                    {...register("text", { required: `${t('Field is required')}` })}
                  />
                  <div className="text-danger">{errors?.text?.message}</div>
                </div>

                <div className='mb-3'>
                  <label>{t('Sorting')}</label>
                  <div className='mb-2'>
                    <input type="text" className="form-control"
                      {...register("sort_num")}
                    />
                  </div>
                </div>

                <div className='mb-3'>
                  <label>{t('Space')}</label>
                  <div className='mb-2'>
                    <input type="text" className="form-control"
                      {...register("space")}
                    />
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {t('Close')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </form>
          </div>
      }
    </Modal>
  );
}

export default InvoiceFooterModal;