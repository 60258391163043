import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getAllTransactions = async () => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  try {
    const response = await axios.get(backendUrl + '/api/get_all_transactions/'+currentLng, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const deleteTransaction = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/delete_transaction', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const approveTransaction = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/approve_transaction', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const transactionService = {
  getAllTransactions,
  deleteTransaction,
  approveTransaction
}

export default transactionService