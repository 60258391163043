import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm } from "react-hook-form";
import Loading from '../../Layouts/Loading';
import invoiceDesginService from '../../../actions/invoiceDesginService';

const PageContainer = () => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, register } = useForm({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const getInvoiceDesign = async () => {
    setLoading(true)
    const res = await invoiceDesginService.getInvoiceDesign();
    setValue('fieldArray', res?.result);
    setLoading(false)
  }

  useEffect(() => {
    getInvoiceDesign()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    const newData = data.fieldArray
    const res = await invoiceDesginService.updateInvoiceDesign(newData);
    setResponse(res)
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Invoice Design')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              !response.success && response.result &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(response.result)}
              </div>
            }
            {
              response.success && response.result &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Changing is successed')}
              </div>

            }
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Left margin')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.left_margin")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Right margin')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.right_margin")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Top margin')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.top_margin")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Bottom margin')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.bottom_margin")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Logo alignment')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.logo_align")}
                >
                  <option value="left">{t('Left')}</option>
                  <option value="right">{t('Right')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Alignment of the address')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.address_align")}
                >
                  <option value="left">{t('Left')}</option>
                  <option value="right">{t('Right')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Date Format')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.show_date")}
                >
                  <option value="datetime">{t('DateTime')}</option>
                  <option value="date">{t('Date')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Customer number')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.cus_num_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Document number')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.doc_num_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Payment method')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.payment_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Display customer email address')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.cus_mail_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Display customer phone number')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.cus_tel_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Tax number of the customer')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.cus_tax_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Display comments')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.comment_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Customer number Prefix')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.cus_num_prefix")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Bill number Prefix')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.inv_num_prefix")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Bill number Suffix')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.inv_num_suffix")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('PDF invoice in the customer account')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.acc_pdf_act")}
                >
                  <option value="0">{t('No')}</option>
                  <option value="1">{t('Yes')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Show footer')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.footer_option")}
                >
                  <option value="ever">{t('ever')}</option>
                  <option value="first">{t('first')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Display logo')}:
              </label>
              <div className='col-lg-4'>
                <select className='form-control'
                  {...register("fieldArray.logo_option")}
                >
                  <option value="ever">{t('ever')}</option>
                  <option value="first">{t('first')}</option>
                  <option value="never">{t('never')}</option>
                </select>
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Logo width')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.logo_width")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Receipt address') + '(' + t('Horizontal') + ')'}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.rec_address")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Font size of address field')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.address_font")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Font size of footertext')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.footer_font")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('General font size')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.general_font")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Company tax number')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.company_taxnum")}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <label className='col-lg-4 col-form-label mobile-left'>
                {t('Footer height')}:
              </label>
              <div className='col-lg-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.footer_height")}
                />
              </div>
            </div>

            <div className='row mt-3 mb-3'>
              <label className='col-lg-4 col-form-label mobile-left'>
              </label>
              <div className='col-lg-4'>
                <input type="submit" className='btn btn-primary' value={t('Submit')} />
              </div>
            </div>

          </form>
        </div>
      </div>
  );
}

export default PageContainer;