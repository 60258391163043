import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { useTranslation } from '../../../hooks/useTranslation';
import { backendUrl } from '../../../constants';
import noPhoto from '../../../assets/images/user_black.jpg'
import patientService from '../../../actions/patientService';
import authService from '../../../actions/authService';
import Loading from '../../Layouts/Loading';

const PageContainer = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([])
  const [response, setResponse] = useState({})
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const { auth } = useSelector(store => store.auth)

  const getPatients = async () => {
    setLoading(true)
    const res = await patientService.getAllPatients();
    if(res.success&&res.result){
      setPatients(res.result);
    }
    setLoading(false)
  }

  const deleteUser = async (id) => {
    setLoading(true)
    const res = await authService.deleteUser({ token: auth.token, id });
    if(res.success && res.result) getPatients();
    setResponse(res)
    setLoading(false)
  }

  useEffect(() => {
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    const result = patients.filter((item) => {
      if(search==='') return item;
      return item?.first_name?.toLowerCase().includes(search?.toLowerCase())
          || item?.last_name?.toLowerCase().includes(search?.toLowerCase())
          || item?.email?.toLowerCase().includes(search?.toLowerCase());
    })
    setData(result);
  }, [patients, search])

  const columns = [
    {
      name: t('Photo'),
      selector: (row) => {
        if (row?.photo) {
          return <img src={`${backendUrl}/images/profile/${row.photo}?v=${new Date().getTime()}`} width="40" height="40" className="rounded-circle" alt={row.id} />
        } else {
          return <img src={noPhoto} width="40" height="40" className="rounded-circle" alt={row.id} />
        }
      },
    },
    {
      name: t('Name'),
      selector: (row) => (row?.first_name || '') + ' ' + (row?.last_name || ''),
      sortable: true,
    },
    {
      name: t('Country'),
      selector: (row) => row?.country,
    },
    {
      name: t('Email address'),
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: t('Phone'),
      selector: (row) => row?.phone,
    },
    {
      name: t('Status'),
      selector: (row) => {
        if(row.status === 0) return <span className="badge bg-warning">{t('Unverified Email')}</span>
        if(row.status === 1) return <span className="badge bg-primary">{t('Activated')}</span>
      },
    },
    {
      name: t('Action'),
      cell: row => (
        <>
          <Link to={`/patient/${row.id}`} className='btn btn-primary me-1 btn-sm'><i className="fa fa-pencil-square-o"></i></Link>
          <button className='btn btn-danger btn-sm' onClick={() => deleteUser(row.id)}><i className="fa fa-trash-o"></i></button>
        </>
      )
    }
  ];

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body datableBody">
          {
            !response.success && response.result &&
            <div className='alert alert-danger d-flex align-items-center' role='alert'>
              {t(response.result)}
            </div>
          }
          {
            response.success && response.result &&
            <div className='alert alert-success d-flex align-items-center' role='alert'>
              {t('Patient is deleted successfully')}
            </div>
          }
          <DataTable
            title={t('Patient List')}
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input type="text" placeholder={t('search here')} className='w-25 form-control'
                value={search}
                onChange = {(e) => setSearch(e.target.value) }
              />
            }
          />
        </div>
      </div>
  );
}

export default PageContainer;