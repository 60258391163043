import React from "react";
import { Link } from "react-router-dom";
import { menu } from "../../menu.json";
import { useTranslation } from '../../hooks/useTranslation'

const LayoutQPageHeader = (props) => {
  const { activekey } = props
  const {t} = useTranslation();

  return (
    <div className="sidebar shadow-sm sticky-xl-top">
      <div className="container">
        <ul className="menu-list">
          {menu.map((d, i) =>
            <li key={"menusingle" + i} className={d.children.length > 0 ? 'collapsed' : ''}>
              <Link className={`m-link ${activekey === "/" + d.routerLink ? 'active' : ''}`} to={d.routerLink ? process.env.PUBLIC_URL + "/" + d.routerLink : '#'}><i className={d.iconClass}></i> <span>{t(d.name)}</span></Link>
              {
                d.children.length > 0 ?
                  <ul className="sub-menu collapse show">
                    {
                      d.children.map((data, ind) => {
                        return <li key={"figf" + ind} >
                          {data.children.length === 0 ? <Link className="ms-link" to={process.env.PUBLIC_URL + "/" + data.routerLink}>{t(data.name)}</Link> :
                            <a className="m-link" href="#!" >{data.name}<i className="fa fa-angle-right flex-grow-1 text-end"></i></a>}
                          {
                            data.children.length > 0 ?
                              <ul className="sub-menu collapse show">
                                {
                                  data.children.map((datasub, indsub) => {
                                    return <li key={"sfdgh" + indsub}><Link className="ms-link" to={process.env.PUBLIC_URL + "/" + datasub.routerLink}>{t(datasub.name)}</Link></li>
                                  })
                                }
                              </ul>
                              : null
                          }
                        </li>

                      })
                    }
                  </ul>
                  : null
              }
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default LayoutQPageHeader;