import React from "react";
import PageContainer from "../components/Dashboard/PageContainer";
import CheckLoggedin from '../components/Auth/CheckLoggedin'

const Dashboard = () => {
  return (
    <CheckLoggedin>
      <div className="container">
        <PageContainer />
      </div>
    </CheckLoggedin>
  )
}

export default Dashboard;