import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getFeedbacks = async () => {
  try {
    const response = await axios.get(backendUrl + '/api/get_feedbacks/'+currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const createFeedback = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/create_feedback/'+currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const updateFeedback = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/update_feedback/'+currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const deleteFeedback = async (data) => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  data.token = token
  try {
    const response = await axios.post(backendUrl + '/api/delete_feedback', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const feedbackService = {
  getFeedbacks,
  updateFeedback,
  createFeedback,
  deleteFeedback
}

export default feedbackService