import { combineReducers } from 'redux';
import dashboardReducer from "./DashboardReducer";
import authReducer from "./auth";
import configsReducer from './configs';
import textsReducer from './texts';

export default combineReducers({
    texts: textsReducer,
    auth: authReducer,
    configs: configsReducer,
    dashboardReducer
})