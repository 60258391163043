import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import DataTable from "react-data-table-component";
import expertiseService from '../../../actions/expertiseService';
import Loading from '../../Layouts/Loading';
import ExpertiseModal from './ExpertiseModal';

const PageContainer = () => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('');
  const [expertise, setExpertise] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expertises, setExpertises] = useState([]);
  const [response, setResponse] = useState({})
  const [search, setSearch] = useState('');
  const [data, setData] = useState([])

  const getExpertises = async () => {
    setLoading(true)
    const res = await expertiseService.getAllExpertises();
    if (res.success && res.result) setExpertises(res.result);
    setLoading(false)
  }

  useEffect(() => {
    getExpertises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteExpertise = async (id) => {
    setLoading(true)
    const res = await expertiseService.deleteExpertise({ id });
    if (res.success && res.result) {
      const newData = expertises.filter((item) => item.id !== id);
      setExpertises(newData)
    }
    setResponse(res)
    setLoading(false)
  }

  useEffect(() => {
    const result = expertises.filter((item) => {
      if (search === '') return item;
      return item?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    setData(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertises, search])

  const handleCloseModal = () => {
    setIsModal(false);
  }

  const addExpertise = () => {
    setExpertise({})
    setTitle('Add New Expertise')
    setIsModal(true);
  }

  const editExpertise = (id) => {
    const found = data.find(obj => obj.id === id);
    setExpertise(found)
    setTitle('Edit Expertise')
    setIsModal(true);
  }

  const handleUpdate = (data) => {
    let _expertises = [...expertises]
    const found = expertises.find(obj => obj.id === data.id);
    if(found){
      _expertises = _expertises.map(expertise => {
        if (expertise.id === data.id) {
          return data
        }
        return expertise
      })
      setExpertises(_expertises)
    } else {
      _expertises.push(data)
      setExpertises(_expertises)
      setResponse({success: true, result: 'added' });
      setIsModal(false);
    }
  }

  const columns = [
    {
      name: t('Name'),
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: '',
      cell: row => (
        <div className='text-end w-100'>
          <button className='btn btn-primary me-1 btn-sm' onClick={() => editExpertise(row.id)}><i className="fa fa-pencil-square-o"></i></button>
          <button className='btn btn-danger btn-sm' onClick={() => deleteExpertise(row.id)}><i className="fa fa-trash-o"></i></button>
        </div>
      )
    }
  ];

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body datableBody">
          <div className='card-title mb-4'><h4>{t('All Expertises')}</h4></div>
          <div className='row'>
            <div className='col-lg-3'></div>
            <div className='col-lg-6'>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {  response.result ==='added' ? t('Submit is successed') : t('Deleting is successed!')}
                </div>
              }

              <DataTable
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                subHeader
                noHeader={true}
                subHeaderComponent={
                  <div className='w-100 d-flex justify-content-between'>
                    <button className='btn btn-primary btn-sm' onClick={() => addExpertise()}>{t('Add Expertise')}</button>
                    <input type="text" placeholder={t('search here')} className='w-25 form-control'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {isModal &&
          <ExpertiseModal
            isModal={isModal}
            handleCloseModal={handleCloseModal}
            title={title}
            expertise={expertise}
            handleUpdate = {handleUpdate}
          />
        }

      </div>

  )
}

export default PageContainer;