import React from "react";
import PageContainer from "../../components/HomePage/Blogs/PageContainer";
import CheckLoggedin from "../../components/Auth/CheckLoggedin";

const Blogs = () => {
  return (
    <CheckLoggedin>
      <div className="container">
        <PageContainer />
      </div>
    </CheckLoggedin>
  )
}

export default Blogs;