import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useParams } from 'react-router-dom';
import mailService from '../../../actions/mailService';
import Loading from '../../Layouts/Loading';
import { useForm } from "react-hook-form";

const PageContainer = () => {
  const { t } = useTranslation();
  const { name } = useParams();
  const [data, setData] = useState({})
  const [loading, setLoading] = useState([])
  const [response, setResponse] = useState({})

  const { handleSubmit, register, formState: { errors } } = useForm({})

  const get_mail = async (name) => {
    setLoading(true)
    const res = await mailService.getMail(name);
    if (res.success && res.result) setData(res.result);
    setLoading(false)
  }

  useEffect(() => {
    get_mail(name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    data.name = name
    const res = await mailService.updateMail(data);
    setResponse(res)
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Edit Email Text')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              !response.success && response.result &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(response.result)}
              </div>
            }
            {
              response.success && response.result &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Email text is changed successfully')}
              </div>

            }
            <div className='row'>
              <div className='col-lg-2'></div>
              <div className='col-lg-8 mb-2'>
                <textarea className='form-control' rows="12" defaultValue={data.text}
                  {...register("text", { required: `${t('Field is required')}` })}
                />
                <div className="text-danger">{errors?.text?.message}</div>
              </div>
            </div>
            <div className='text-center'>
              <button type="submit" className="btn btn-primary me-2">{t('Submit')}</button>
            </div>
          </form>
        </div>
      </div>
  );
}

export default PageContainer;