import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Layouts/Loading';
import { updateConfigs, reset } from '../../../reducers/configs';
import { useForm } from "react-hook-form";
import { backendUrl } from '../../../constants';
import Switch from "react-switch";

const PageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { configs, error, message, loading } = useSelector(store => store.configs)
  const { auth } = useSelector(store => store.auth)
  const [logo, setLogo] = useState(backendUrl + '/images/site_logo.png?v=' + new Date().getTime());
  const [lang, setLang] = useState({
    en: configs.en === 'yes',
    de: configs.de === 'yes',
    sp: configs.sp === 'yes',
    it: configs.it === 'yes',
    pr: configs.pr === 'yes',
    fr: configs.fr === 'yes',
    gr: configs.gr === 'yes',
    ar: configs.ar === 'yes',
  })
  const [selectedLogo, setSelectedLogo] = useState(null)
  const { handleSubmit, setValue, register } = useForm({})

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue('site_title', configs?.site_title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs, message])

  const onImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedLogo(file)
    setLogo(URL.createObjectURL(file));
  };

  const handleChangeActive = (v) => {
    setLang({ ...lang, [v]: !lang[v] })
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('site_logo_file', selectedLogo);
    formData.append('en', lang.en ? 'yes' : 'no')
    formData.append('de', lang.de ? 'yes' : 'no')
    formData.append('sp', lang.sp ? 'yes' : 'no')
    formData.append('it', lang.it ? 'yes' : 'no')
    formData.append('pr', lang.pr ? 'yes' : 'no')
    formData.append('fr', lang.fr ? 'yes' : 'no')
    formData.append('gr', lang.gr ? 'yes' : 'no')
    formData.append('ar', lang.ar ? 'yes' : 'no')
    const entries = Object.entries(data);
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    dispatch(updateConfigs(formData))
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Site Settings')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              error && message &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(message)}
              </div>
            }
            {
              !error && message &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Changing is successed')}
              </div>

            }
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label mobile-left'>
                {t('Site Logo')}:
              </label>
              <div className='col-lg-3'>
                <label htmlFor="site_logo_file" className='cursor-pointer d-block text-center'>
                  <img src={logo} alt={t('Profile')} style={{ width: '100%', maxWidth: '150px' }} className="mb-2" />
                  <br />
                </label>
                <input type="file" className="form-control d-none" id="site_logo_file" onChange={onImageChange} accept="image/png, image/gif, image/jpeg" />
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-3 col-form-label mobile-left'>
                {t('Site Title')}:
              </label>
              <div className='col-lg-6'>
                <input type="text" className='form-control'
                  {...register("site_title")}
                />
              </div>
            </div>

            {/* ============== Langauage Part ===================== */}
            <div className='row mb-3'>
              <div className='col-lg-3 mobile-left fw-bold mb-2'>
                {t('Language')}:
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('English')}: 
                    <Switch
                      onChange={() => handleChangeActive('en')}
                      checked={lang.en}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('German')}: 
                    <Switch
                      onChange={() => handleChangeActive('de')}
                      checked={lang.de}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('Spanish')}: 
                    <Switch
                      onChange={() => handleChangeActive('sp')}
                      checked={lang.sp}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('Italian')}: 
                    <Switch
                      onChange={() => handleChangeActive('it')}
                      checked={lang.it}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('Portuguese')}:
                    <Switch
                      onChange={() => handleChangeActive('pr')}
                      checked={lang.pr}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('French')}:
                    <Switch
                      onChange={() => handleChangeActive('fr')}
                      checked={lang.fr}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('Greek')}: 
                    <Switch
                      onChange={() => handleChangeActive('gr')}
                      checked={lang.gr}
                      width={50}
                      height={20}
                    />
                  </div>
                  <div className='col-lg-4 col-md-6 mb-2 d-flex align-items-center justify-content-between ps-5'>
                    {t('Arabic')}: 
                    <Switch
                      onChange={() => handleChangeActive('ar')}
                      checked={lang.ar}
                      width={50}
                      height={20}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='text-center'>
              <button type="submit" className="btn btn-primary me-2">{t('Submit')}</button>
            </div>
          </form>
        </div>
      </div>
  );
}

export default PageContainer;