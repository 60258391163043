import React from "react";
import { Route, } from "react-router-dom";
import AuthLeft from "../components/Auth/AuthLeft";
import ForgotPassword from "../components/Auth/ForgotPassword";
import SignInTile from "../components/Auth/SignInTile";

const AuthIndex = () => {
  return (
    <div className="body d-flex p-0 p-xl-5">
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center rounded-lg auth-h100">
            <AuthLeft />
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
            <Route exact path={`${process.env.PUBLIC_URL}/signin`} component={SignInTile} />
            <Route exact path={`${process.env.PUBLIC_URL}/forgotpassword`} component={ForgotPassword} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthIndex;