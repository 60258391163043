import React from "react";
import NoDataImage from "../../assets/images/no-data.svg"

class PageContainer extends React.Component {
  render() {
    return (
      <div className="card mb-3">
        <div className="card-body text-center p-5">
          <img src={NoDataImage} alt="No Data" className="w120" />
          <div className="mt-4 mb-3">
            <span className="text-muted">No data to show</span>
          </div>
          <button type="button" className="btn btn-white border lift me-1">Get Started</button>
          <button type="button" className="btn btn-primary border lift ml-1">Back to Home</button>
        </div>
      </div>
    );
  }
}

export default PageContainer;