import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const SettingsModal = (props) => {
  const { activeId, activeIdSidebarBack, onChangeGradient, onClickPrimaryColor, onClickouter, onClickSecondaryColor, onClickchartColorPicker1, onClickchartColorPicker2, onClickchartColorPicker3, onClickchartColorPicker4, onClickchartColorPicker5, isGradient, show, closeModal, isBackgroundImage, onEnableSidebarBack, activatedSidebarBack, colourPicker } = props;

  const [chenge, setChenge] = useState("theme-blue")
  const [fontsChange, setFontsChange] = useState("font-opensans")
  const [darkLightMode, setDarkLightMode] = useState("light")

  const onChangeTheme = (theme) => {
    var ele = document.getElementById(activeId);
    ele.classList.remove("theme-indigo");
    document.body.classList.remove("theme-indigo")
    ele.classList.remove("theme-blue");
    document.body.classList.remove("theme-blue")
    ele.classList.remove("theme-cyan");
    document.body.classList.remove("theme-cyan")
    ele.classList.remove("theme-green");
    document.body.classList.remove("theme-green")
    ele.classList.remove("theme-orange");
    document.body.classList.remove("theme-orange")
    ele.classList.remove("theme-blush");
    document.body.classList.remove("theme-blush")
    ele.classList.remove("theme-red");
    document.body.classList.remove("theme-red")
    ele.classList.remove("theme-dynamic");
    document.body.classList.remove("theme-dynamic")

    ele.classList.add(theme);
    document.body.classList.add(theme)

    setChenge(theme)
  }

  const onChangeFonts = (fonts) => {
    document.body.classList.remove("font-opensans");
    document.body.classList.remove("font-quicksand");
    document.body.classList.remove("font-nunito");
    document.body.classList.remove("font-raleway");

    document.body.classList.add(fonts);
    setFontsChange(fonts)
  }

  const onChangeDarkMode = () => {
    // console.log(window.document.children[0].getAttribute("data-theme")); //window.document.children[0].getAttribute("data-theme")
    if (window.document.children[0].getAttribute("data-theme") === "light") {
      window.document.children[0].setAttribute("data-theme", "dark")
      setDarkLightMode("dark")
    } else {
      window.document.children[0].setAttribute("data-theme", "light")
      setDarkLightMode("light")
    }
  }

  const onChangeHighContrast = () => {
    if (window.document.children[0].getAttribute("data-theme") === "high-contrast") {
      window.document.children[0].setAttribute("data-theme", "light")
      setDarkLightMode("light")
    } else {
      window.document.children[0].setAttribute("data-theme", "high-contrast")
      setDarkLightMode("high-contrast")
    }
  }

  const onChangeRTLMode = () => {
    if (document.body.classList.contains("rtl_mode")) {
      document.body.classList.remove("rtl_mode");
    } else {
      document.body.classList.add("rtl_mode");
    }
  }

  const onChangeCheckboxBackImg = async () => {
    var sidebar = document.getElementsByClassName("sidebar");
    if (sidebar) {
      if (sidebar.length > 0) {
        if (isBackgroundImage) {
          sidebar[0].classList.add("sidebar-img-bg");
        } else {
          if (sidebar[0].classList.contains("sidebar-img-bg")) {
            sidebar[0].classList.remove("sidebar-img-bg");
          }
        }
      }
    }
  }

  const onChangeBackImage = (n) => {
    document.documentElement.style.setProperty('--sidebar-img', 'url(https://alui.thememakker.com/html/assets/images/sidebar-bg/sidebar-' + n + '.jpg)');
    activeIdSidebarBack(n);
  }
  return (
    <Modal id="SettingsModal" show={show} onHide={closeModal} size="sm" className="modal-dialog-centered modal-dialog-scrollable" centered onClick={onClickouter} >
      <Modal.Header >
        <h5 className="modal-title">AL-UI Setting</h5>
        <button type="button" className="btn-close" onClick={closeModal}></button>
      </Modal.Header>
      <Modal.Body>
        <div className="setting-font">
          <small className="card-title text-muted">Google font Settings</small>
          <ul className="list-group font_setting mb-3 mt-1">
            <li className="list-group-item py-1 px-2">
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={fontsChange === "font-opensans" ? true : false} name="font" id="font-opensans" value="font-opensans" onChange={() => { onChangeFonts("font-opensans") }} />
                <label className="form-check-label" >
                  Open Sans Google Font
                </label>
              </div>
            </li>
            <li className="list-group-item py-1 px-2">
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={fontsChange === "font-quicksand" ? true : false} name="font" id="font-quicksand" value="font-quicksand" onChange={() => { onChangeFonts("font-quicksand") }} />
                <label className="form-check-label" >
                  Quicksand Google Font
                </label>
              </div>
            </li>
            <li className="list-group-item py-1 px-2">
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={fontsChange === "font-nunito" ? true : false} name="font" id="font-nunito" value="font-nunito" onChange={() => { onChangeFonts("font-nunito") }} />
                <label className="form-check-label" >
                  Nunito Google Font
                </label>
              </div>
            </li>
            <li className="list-group-item py-1 px-2">
              <div className="form-check mb-0">
                <input className="form-check-input" type="radio" checked={fontsChange === "font-raleway" ? true : false} name="font" id="font-Raleway" value="font-raleway" onChange={() => { onChangeFonts("font-raleway") }} />
                <label className="form-check-label" >
                  Raleway Google Font
                </label>
              </div>
            </li>
          </ul>
        </div>
        <div className="setting-theme">
          <small className="card-title text-muted">Theme Color Settings</small>
          <ul className="list-unstyled d-flex justify-content-between choose-skin mb-2 mt-1">
            <li data-theme="indigo" className={chenge === "theme-indigo" ? "active" : ""} onClick={() => { onChangeTheme("theme-indigo") }}><div className="indigo"></div></li>
            <li data-theme="blue" className={chenge === "theme-blue" ? "active" : ""} onClick={() => { onChangeTheme("theme-blue") }}><div className="blue"></div></li>
            <li data-theme="cyan" className={chenge === "theme-cyan" ? "active" : ""} onClick={() => { onChangeTheme("theme-cyan") }}><div className="cyan"></div></li>
            <li data-theme="green" className={chenge === "theme-green" ? "active" : ""} onClick={() => { onChangeTheme("theme-green") }}><div className="green"></div></li>
            <li data-theme="orange" className={chenge === "theme-orange" ? "active" : ""} onClick={() => { onChangeTheme("theme-orange") }}><div className="orange"></div></li>
            <li data-theme="blush" className={chenge === "theme-blush" ? "active" : ""} onClick={() => { onChangeTheme("theme-blush") }}><div className="blush"></div></li>
            <li data-theme="red" className={chenge === "theme-red" ? "active" : ""} onClick={() => { onChangeTheme("theme-red") }}><div className="red"></div></li>
            <li data-theme="dynamic" className={chenge === "theme-dynamic" ? "active" : ""} onClick={() => { onChangeTheme("theme-dynamic") }}><div className="dynamic"><i className="fa fa-paint-brush"></i></div></li>
          </ul>
          <div className="form-check form-switch gradient-switch mb-1">
            <input className="form-check-input" type="checkbox" checked={isGradient} id="CheckGradient" onChange={onChangeGradient} />
            <label className="form-check-label" >Enable Gradient! ( Sidebar )</label>
          </div>
        </div>
        <div className="setting-img mb-3">
          <div className="form-check form-switch imagebg-switch mb-1">
            <input className="form-check-input" type="checkbox" checked={isBackgroundImage} id="CheckImage" onChange={async () => { await onEnableSidebarBack(); onChangeCheckboxBackImg(); }} />
            <label className="form-check-label" htmlFor="CheckImage">Set Background Image (Sidebar)</label>
          </div>
          <div className={isBackgroundImage ? "bg-images show" : "bg-images"}>
            <ul className="list-unstyled d-flex justify-content-between">
              <li className={`sidebar-img-1 ${activatedSidebarBack === 1 ? 'sidebar-img-active' : ''}`} ><a onClick={(e) => { e.preventDefault(); onChangeBackImage(1); }} className="rounded sidebar-img" id="img-1" href="#!"><img src={require("../../assets/images/sidebar-bg/sidebar-1.jpg")} alt="" /></a></li>
              <li className={`sidebar-img-2 ${activatedSidebarBack === 2 ? 'sidebar-img-active' : ''}`} ><a onClick={(e) => { e.preventDefault(); onChangeBackImage(2); }} className="rounded sidebar-img" id="img-2" href="#!"><img src={require("../../assets/images/sidebar-bg/sidebar-2.jpg")} alt="" /></a></li>
              <li className={`sidebar-img-3 ${activatedSidebarBack === 3 ? 'sidebar-img-active' : ''}`} ><a onClick={(e) => { e.preventDefault(); onChangeBackImage(3); }} className="rounded sidebar-img" id="img-3" href="#!"><img src={require("../../assets/images/sidebar-bg/sidebar-3.jpg")} alt="" /></a></li>
              <li className={`sidebar-img-4 ${activatedSidebarBack === 4 ? 'sidebar-img-active' : ''}`} ><a onClick={(e) => { e.preventDefault(); onChangeBackImage(4); }} className="rounded sidebar-img" id="img-4" href="#!"><img src={require("../../assets/images/sidebar-bg/sidebar-4.jpg")} alt="" /></a></li>
              <li className={`sidebar-img-5 ${activatedSidebarBack === 5 ? 'sidebar-img-active' : ''}`} ><a onClick={(e) => { e.preventDefault(); onChangeBackImage(5); }} className="rounded sidebar-img" id="img-5" href="#!"><img src={require("../../assets/images/sidebar-bg/sidebar-5.jpg")} alt="" /></a></li>
            </ul>
          </div>
        </div>
        <div className="dt-setting">
          <small className="card-title text-muted">Dynamic Color Settings</small>
          <ul className="list-group list-unstyled mb-3 mt-1">
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label>Primary Color</label>
              <button id="primaryColorPicker" onClick={onClickPrimaryColor} className="btn bg-primary avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label>Secondary Color</label>
              <button id="secondaryColorPicker" onClick={onClickSecondaryColor} className="btn bg-secondary avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label className="text-muted small">Chart Color 1</label>
              <button id="chartColorPicker1" onClick={onClickchartColorPicker1} className="btn chart-color1 avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label className="text-muted small">Chart Color 2</label>
              <button id="chartColorPicker2" onClick={onClickchartColorPicker2} className="btn chart-color2 avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label className="text-muted small">Chart Color 3</label>
              <button id="chartColorPicker3" onClick={onClickchartColorPicker3} className="btn chart-color3 avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label className="text-muted small">Chart Color 4</label>
              <button id="chartColorPicker4" onClick={onClickchartColorPicker4} className="btn chart-color4 avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
              <label className="text-muted small">Chart Color 5</label>
              <button id="chartColorPicker5" onClick={onClickchartColorPicker5} className="btn chart-color5 avatar xs border-0 rounded-0 colorpicker-element"></button>
            </li>
          </ul>
          {colourPicker ? colourPicker() : null}
        </div>
        <div className="setting-mode">
          <small className="card-title text-muted">Light/Dark &amp; Contrast Layout</small>
          <ul className="list-group list-unstyled mb-0 mt-1">
            <li className="list-group-item d-flex align-items-center py-1 px-2">
              <div className="form-check form-switch theme-switch mb-0">
                <input className="form-check-input" type="checkbox" disabled={darkLightMode === "high-contrast" ? true : false} checked={darkLightMode === "dark" ? true : false} id="theme-switch" onChange={() => { onChangeDarkMode(); }} />
                <label className="form-check-label" >Enable Dark Mode!</label>
              </div>
            </li>
            <li className="list-group-item d-flex align-items-center py-1 px-2">
              <div className="form-check form-switch theme-high-contrast mb-0">
                <input className="form-check-input" type="checkbox" checked={darkLightMode === "high-contrast" ? true : false} id="theme-high-contrast" onChange={() => { onChangeHighContrast(); }} />
                <label className="form-check-label" >Enable High Contrast</label>
              </div>
            </li>
            <li className="list-group-item d-flex align-items-center py-1 px-2">
              <div className="form-check form-switch theme-rtl mb-0">
                <input className="form-check-input" type="checkbox" id="theme-rtl" checked={document.body.classList.contains("rtl_mode")} onChange={() => { onChangeRTLMode() }} />
                <label className="form-check-label" >Enable RTL Mode!</label>
              </div>
            </li>
          </ul>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary lift" onClick={closeModal}>Save Changes</button>
        <button type="button" className="btn btn-white border lift" data-dismiss="modal" onClick={closeModal}>Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default SettingsModal;