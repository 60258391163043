import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Link } from 'react-router-dom';

const PageContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className='card-title mb-4'><h4>{t('Email Setting')}</h4></div>
        <div className='row'>
          <div className='col-md-2'></div>
          <div className='col-md-8'>
            <ul className="list-unstyled mb-3 todo-list">
              <li>
                <div className="d-flex justify-content-between pb-1 mb-3 border-bottom">
                  <span>{t('Email text for account reigstration')}</span>
                  <span>
                    <Link to='mail_edit/mail01' className='btn btn-primary me-1 btn-sm'><i className="fa fa-pencil-square-o"></i></Link>
                  </span>
                </div>
              </li>

              <li>
                <div className="d-flex justify-content-between pb-1 mb-3 border-bottom">
                  <span>{t('Email text for password forgot')}</span>
                  <span>
                    <Link to='mail_edit/mail02' className='btn btn-primary me-1 btn-sm'><i className="fa fa-pencil-square-o"></i></Link>
                  </span>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageContainer;