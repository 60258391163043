import React from "react";
import PageContainer from "../../components/Settings/GoogleSetting/PageContainer";
import CheckLoggedin from "../../components/Auth/CheckLoggedin";

const GoogleSetting = () => {
  return (
    <CheckLoggedin>
      <div className="container">
        <PageContainer />
      </div>
    </CheckLoggedin>
  )
}

export default GoogleSetting;