import React, { useState, useEffect } from 'react';
import BasicInformation from '../BasicInformation'
import { useTranslation } from '../../../hooks/useTranslation';
import Loading from '../../Layouts/Loading';
import { useParams } from "react-router-dom";
import authService from '../../../actions/authService';

const PageContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const get_user = async () => {
      setLoading(true)
      const res = await authService.getUser(id);
      if(res.success && res.result){
        setUser(res.result);
      }
      setLoading(false)
    }
    get_user()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Doctor Information')}</h4></div>
          <BasicInformation user={user}/>
        </div>
      </div>
  );
}

export default PageContainer;