export const onChangeActiveLayout = val => disptch => {
    disptch({
      type: "ON_CHANGE_ACTIVE_LAYOUT",
      payload: val
    });
};

export const onChangeGradient = val => disptch => {
  disptch({
    type: "ON_CHANGE_GRADIENT",
    payload: val
  });
};

export const openSettingModal = val => disptch => {
  disptch({
    type: "OPEN_SETTING_MODAL",
    payload: val
  });
};

export const openLayoutModal = val => disptch => {
  disptch({
    type: "OPEN_LAYOUT_MODAL",
    payload: val
  });
};

export const openNotificationModal = val => disptch => {
  disptch({
    type: "OPEN_NOTIFICATION_MODAL",
    payload: val
  });
};

export const openSearchModal = val => disptch => {
  disptch({
    type: "OPEN_SEARCH_MODAL",
    payload: val
  });
};

export const onChangeRoute = val => disptch => {
  disptch({
    type: "ON_CHANGE_ROUTE",
    payload: val
  });
};

export const onEnableSidebarBack = val => disptch => {
  disptch({
    type: "ON_ENABLE_SIDEBAR_BACK",
    payload: val
  });
};

export const activeIdSidebarBack = val => disptch=>{
  disptch({
    type: "ACTIVE_ID_SIDEBAR_BACK",
    payload: val
  });
}