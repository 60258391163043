import React from 'react';
import BasicInformation from '../BasicInformation'
import { useTranslation  } from '../../../hooks/useTranslation';

const PageContainer = () => {
  const {t} = useTranslation();
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className='card-title mb-4'><h4>{t('Patient Information')}</h4></div>
        <BasicInformation/>
      </div>
    </div>
  );
}

export default PageContainer;