import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm } from "react-hook-form";
import Loading from '../../Layouts/Loading';
import countryService from '../../../actions/countryService';

const CountryModal = (props) => {
  const { isModal, handleCloseModal, title, country, handleUpdate } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})
  const { handleSubmit, setValue, setError, register, formState: { errors } } = useForm({})

  useEffect(() => {
    setValue('name', country.name);
    setError('name', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  const onSubmit = (data) => {
    submitData(data);
  }

  const submitData = async (data) => {
    setLoading(true)
    let res;
    if (Object.keys(country).length) {
      data.id = country.id
      res = await countryService.upadateCountry(data);
    } else {
      res = await countryService.createCountry(data);
    }

    if (res.success && res.result) handleUpdate(res.result)
    setResponse(res)
    setLoading(false)
  }


  return (
    <Modal show={isModal} onHide={handleCloseModal} centered>
      {
        loading
          ? <Loading />
          :
          <div>
            <Modal.Header>
              <Modal.Title>{t(title)}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {t('Submit is successed')}
                </div>
              }
              <Modal.Body>
                <label>{t('Name')}</label>
                <div className='mb-2'>
                  <div className="input-group">
                    <input type="text" className="form-control"
                      {...register("name", { required: `${t('Field is required')}` })}
                    />
                  </div>
                  <div className="text-danger">{errors?.name?.message}</div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {t('Close')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </form>
          </div>
      }
    </Modal>
  );
}

export default CountryModal;