import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../Layouts/Loading";
import { userLogin, reset } from '../../reducers/auth'

const SignInTile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth, loading, error, message } = useSelector(store => store.auth)

  useEffect(() => {
    if(auth?.role === 'admin'){
      history.push('/');
    }
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data) => {
    dispatch(userLogin(data));
  }

  return (
    loading
      ? <Loading />
      :
      <div className="w-100 p-4 p-md-5 card border-0" style={{ maxWidth: '32rem' }}>
        <form className="row g-1 p-0 p-md-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 text-center mb-5">
            <h1>{t('Sign in')}</h1>
          </div>
          {message && error && <div className='alert alert-danger' role='alert'>{t(message)}</div>}
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">{t('Email address')}</label>
              <input type="email" className="form-control form-control-lg" placeholder="name@example.com" autoComplete="off"
                {...register("email", { required: `${t('Field is required')}` })}
              />
              <div className="text-danger">{errors?.email?.message}</div>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-label">
                <span className="d-flex justify-content-between align-items-center">
                  {t('Password')}
                  <a className="text-primary" href="#!" onClick={(e) => { e.preventDefault(); history.push(`${process.env.PUBLIC_URL}/forgotpassword`) }}>{t('Forgot Password?')}</a>
                </span>
              </div>
              <input type="password" className="form-control form-control-lg" placeholder="***************" autoComplete="off"
                {...register("password", { required: `${t('Field is required')}` })}
              />
              <div className="text-danger">{errors?.password?.message}</div>
            </div>
          </div>
          <div className="col-12 text-center mt-4">
            <input className="form-control" type="hidden" value="admin" {...register("role")} />
            <button className="btn btn-lg btn-block btn-dark lift text-uppercase">{t('Sign in')}</button>
          </div>
        </form>
      </div>
  )
}

export default SignInTile;