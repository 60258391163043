import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import reducers from './reducers'
import App from './App';

import "./assets/scss/main.scss";
// import "./assets/css/al.style.min.css";
import { BrowserRouter } from 'react-router-dom';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage', 'htmlTag'],
      caches: ['localStorage']
    }
  });

const store = createStore(reducers, applyMiddleware(thunk));
ReactDOM.render(
  <Suspense fallback="...loading">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

