import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import CKEditor from "react-ckeditor-component";
import pagesService from '../../actions/pagesService';
import Loading from '../Layouts/Loading';

const PageContainer = () => {
  const { page } = useParams();
  const { t } = useTranslation();
  const [content, setContent] = useState('')
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false)

  const getPage = async () => {
    setLoading(true)
    const res = await pagesService.getPage(page)
    setContent(res?.result?.text);
    setLoading(false)
  }

  useEffect(() => {
    setResponse({})
    getPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const onChange = (evt) => {
    var newContent = evt.editor.getData();
    setContent(newContent)
  }

  const onSubmit = async () => {
    const data = {
      page: page,
      text: content,
    }
    setLoading(true)
    const res = await pagesService.updatePage(data);
    setResponse(res)
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Edit Page')}</h4></div>
          {
            !response.success && response.result &&
            <div className='alert alert-danger d-flex align-items-center' role='alert'>
              {t(response.result)}
            </div>
          }
          {
            response.success && response.result &&
            <div className='alert alert-success d-flex align-items-center' role='alert'>
              {t('Submit is successed')}
            </div>

          }
          <div className='mb-4'>
            <CKEditor
              activeClass="p10"
              content={content}
              events={{
                "change": onChange
              }}
            />
          </div>
          <button className='btn btn-primary' onClick={onSubmit}>{t('Submit')}</button>
        </div>
      </div>
  );
}

export default PageContainer;