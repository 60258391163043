import React from 'react';
import PageContainer from '../../components/Invoices/InvoiceDesign/PageContainer'
import CheckLoggedin from '../../components/Auth/CheckLoggedin';

const InvoiceDesign = () => {
  return (
    <CheckLoggedin>
      <div className="container">
        <PageContainer />
      </div>
    </CheckLoggedin>
  );
}

export default InvoiceDesign;