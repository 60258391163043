import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getAllEmails = async () => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  try {
    const response = await axios.get(backendUrl + '/api/get_all_mails/'+currentLng, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const getMail = async (name) => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  try {
    const response = await axios.get(backendUrl + '/api/get_mail/'+name+'/'+currentLng, {
      headers: { Authorization: `Bearer ${token}` }
  });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const updateMail = async (data) => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  data.token = token
  try {
    const response = await axios.post(backendUrl + '/api/update_mail/'+currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const mailService = {
  getAllEmails,
  getMail,
  updateMail
}

export default mailService