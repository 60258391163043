import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authService from '../actions/authService'

const auth = JSON.parse(localStorage.getItem('admin'));

export const userLogin = createAsyncThunk(
  'auth/userLogin',
  async (data, thunkAPI) => {
    return await authService.userLogin(data);
  }
);

export const userLogout = createAsyncThunk(
  'auth/userLogout',
  async (data, thunkAPI) => {
    return await authService.userLogout(data);
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: auth ? auth : null,
    loading: false,
    error: false,
    message: ''
  },

  reducers: {
    reset: (state) => {
      state.loading = false
      state.error = false
      state.message = ''
    },
    updateAuth: (state, action) => {
      state.auth = { ...state.auth, ...action.payload }
      localStorage.setItem('admin', JSON.stringify(state.auth))
    }
  },

  extraReducers: {
    
    // ============ login =================================
    [userLogin.pending]: (state, action) => {
      state.loading = true
    },
    [userLogin.fulfilled]: (state, action) => {
      state.loading = false
      if(action.payload?.success){
        state.error = false
        state.message = 'success'
        state.auth = action.payload.result;
        state.auth.token = action.payload.token;
      } else {
        state.error = true
        state.message = action.payload.result;
        state.auth = null;
      }
    },

    // ============ logout =================================
    [userLogout.pending]: (state, action) => {
      state.loading = true
    },
    [userLogout.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.auth = null;
      state.message = ''
    },

  },
});

export const { reset, updateAuth } = authSlice.actions

export default authSlice.reducer;
