
const initialState = {
  activeLayout:"LayoutQ",
  isGradient:false,
  isSettingModal:false,
  isNotificationModal:false,
  isSearchModal:false,
  isLocationChanged:false,
  isBackgroundImage:false,
  activatedSidebarBack:1
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ON_CHANGE_ACTIVE_LAYOUT":
      {
        return {
          ...state,
          activeLayout:action.payload
        };
      }

    case "ON_CHANGE_GRADIENT":
    {
      return {
        ...state,
        isGradient:action.payload
      }
    }

    case "OPEN_SETTING_MODAL":
    {
      return {
        ...state,
        isSettingModal:action.payload
      }
    }

    case "OPEN_LAYOUT_MODAL":
    {
      return {
        ...state,
        isLayoutModal:action.payload
      }
    }

    case "OPEN_NOTIFICATION_MODAL":
    {
      return {
        ...state,
        isNotificationModal:action.payload
      }
    }

    case "OPEN_SEARCH_MODAL":
    {
      return {
        ...state,
        isSearchModal:action.payload
      }
    }

    case "ON_CHANGE_ROUTE":
    {
      return {
        ...state,
        isLocationChanged:!state.isLocationChanged
      }
    }

    case "ON_ENABLE_SIDEBAR_BACK":
    {
      return {
        ...state,
        isBackgroundImage:action.payload
      }
    }

    case "ACTIVE_ID_SIDEBAR_BACK":
    {
      return {
        ...state,
        activatedSidebarBack:action.payload
      }
    }

    default:
      return state;
  }
};

export default DashboardReducer;
