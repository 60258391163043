import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import authService from '../../actions/authService';
import { userLogout } from '../../reducers/auth';

const CheckLoggedin = (props) => {
  const dispatch = useDispatch()
  const { auth } = useSelector(store => store.auth)

  const check_loggin = async () => {
    const res = await authService.checkLoggedin(auth?.token)
    if (!res.success && !res.result) {
      dispatch(userLogout(auth?.token))
    }
  }

  useEffect(()=> {
    check_loggin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (  
    props.children
  );
}
 
export default CheckLoggedin;