import React from 'react';
import BasicInformation from './BasicInformation'
import { useTranslation  } from '../../../hooks/useTranslation';
import { useSelector } from 'react-redux';

const PageContainer = () => {
  const {t} = useTranslation();
  const { auth } = useSelector(store => store.auth);
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className='card-title mb-4'><h4>{t('Admin Profile')}</h4></div>
        <BasicInformation user={auth}/>
      </div>
    </div>
  );
}

export default PageContainer;