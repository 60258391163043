import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getAllDeletedDoctors = async () => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  try {
    const response = await axios.get(backendUrl + '/api/get_all_deleted_doctors/'+currentLng, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const getAllOnlineDoctors = async () => {
  try {
    const response = await axios.get(backendUrl + '/api/get_all_online_doctors/'+currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const getAllDoctors = async () => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  try {
    const response = await axios.get(backendUrl + '/api/get_all_doctors/'+currentLng, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const doctorService = {
  getAllDeletedDoctors,
  getAllOnlineDoctors,
  getAllDoctors
}

export default doctorService