import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm, Controller } from "react-hook-form";
import CKEditor from "react-ckeditor-component";
import cookieService from '../../../actions/cookieService';
import Loading from '../../Layouts/Loading';

const PageContainer = () => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, control } = useForm({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const getCookie = async () => {
    setLoading(true)
    const res = await cookieService.getCookie()
    setValue('approval', res?.result?.approval)
    setValue('about_cookie', res?.result?.about_cookie)
    setLoading(false)
  }

  useEffect(() => {
    getCookie()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    const res = await cookieService.updateCookie(data);
    setResponse(res)
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Cookie Settings')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              !response.success && response.result &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(response.result)}
              </div>
            }
            {
              response.success && response.result &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Submit is successed')}
              </div>

            }
            <div className='mb-4'>
              <b>{t('Approval')}</b>
              <Controller
                name='approval'
                control={control}
                render={({
                  field: { value, onChange }
                }) => (
                  <CKEditor
                    activeClass="p10"
                    content={value}
                    events={{
                      "change": (evt)=>onChange(evt.editor.getData())
                    }}
                  />
                )}
              />
            </div>
            <div className='mb-4'>
              <b>{t('About Cookies')}</b>
              <Controller
                name='about_cookie'
                control={control}
                render={({
                  field: { value, onChange }
                }) => (
                  <CKEditor
                    activeClass="p10"
                    content={value}
                    events={{
                      "change": (evt)=>onChange(evt.editor.getData())
                    }}
                  />
                )}
              />
            </div>
            <button type="submit" className="btn btn-primary me-2">{t('Submit')}</button>
          </form>
        </div>
      </div>
  );
}

export default PageContainer;