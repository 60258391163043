import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import stepService from '../../../actions/stepService';
import { IconPickerItem } from 'react-fa-icon-picker'
import Loading from '../../Layouts/Loading';
import StepModal from './StepModal';

const PageContainer = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [row, setRow] = useState({});

  const getSteps = async () => {
    setLoading(true);
    const res = await stepService.getSteps();
    if (res.success && res.result) setData(res.result);
    setLoading(false);
  }

  useEffect(() => {
    getSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseModal = () => {
    setIsModal(false);
  }

  const editRow = (id) => {
    const found = data.find(obj => obj.id === id);
    setRow(found)
    setIsModal(true);
  }

  const handleUpdate = (item) => {
    let _data = [...data]
    const found = data.find(obj => obj.id === item.id);
    if (found) {
      _data = _data.map(obj => {
        if (obj.id === item.id) {
          return item
        }
        return obj
      })
      setData(_data)
    }
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body datableBody">
          <div className='card-title mb-4'><h4>{t('Work Process')}</h4></div>
          <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-8'>
              <div className='overflow-auto'>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t('Icon')}</th>
                      <th>{t('Title')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0
                      ? data.map(item =>
                        <tr key={item.id} style={{ verticalAlign: 'middle' }}>
                          <td>{t('Step') + item.id}</td>
                          <td><IconPickerItem icon={item.icon} /></td>
                          <td>{item?.title}</td>
                          <td className='text-nowrap text-end'>
                            <button className='btn btn-primary me-1 btn-sm' onClick={() => editRow(item.id)}><i className="fa fa-pencil-square-o"></i></button>
                          </td>
                        </tr>
                      )
                      :
                      <tr>
                        <td colSpan={4} className="text-center">{t('No available data')}</td>
                      </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {isModal &&
          <StepModal
            isModal={isModal}
            handleCloseModal={handleCloseModal}
            row={row}
            handleUpdate={handleUpdate}
          />
        }

      </div>
  );
}

export default PageContainer;