import React from "react";

const Footer = () => {
  return (
    <div className="body-footer">
      <div className="container">
        <div className="col-12">
          <div className="card mb-3">
            <div className="card-body">
              <div className="row justify-content-between align-items-center">
                <div className="col">
                  <p className="font-size-sm mb-0">© AL-UI.
                    <span className="d-none d-sm-inline-block">2020 ThemeMakker.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;