import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm, Controller } from "react-hook-form";
import ExpertiseDropdown from '../../Layouts/common/ExpertiseDropdown';
import { useSelector } from 'react-redux';
import Loading from '../../Layouts/Loading';
import blogService from '../../../actions/blogService';
import { backendUrl } from '../../../constants';

const BlogModal = (props) => {
  const { isModal, handleCloseModal, title, row, handleUpdate } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})
  const [selectedFile, setSelectedFile] = useState()

  const [image, setImage] = useState(row?.image ? backendUrl + '/images/blogs/' + row.image + '?v=' + new Date().getTime() : '')
  const { auth } = useSelector(store => store.auth)
  const { handleSubmit, register, control, formState: { errors } } = useForm({
    defaultValues: {
      title: row?.title || '',
      description: row?.description || '',
      image: row?.image || '',
      expertise_id: row?.expertise_id || '',
    }
  })

  const onImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file)
    setImage(URL.createObjectURL(file));
  };

  const submitData = async (data) => {
    setLoading(true)
    let res;
    if (Object.keys(row).length) {
      res = await blogService.updateBlog(data);
    } else {
      res = await blogService.createBlog(data);
    }

    if (res.success && res.result) handleUpdate(res.result)
    setResponse(res)
    setLoading(false)
  }

  const onSubmit = (data) => {
    if (!image) {
      setResponse({ success: false, result: 'Please select an image' });
    } else {
      const formData = new FormData();
      formData.append('token', auth.token);
      formData.append('image_file', selectedFile);
      const entries = Object.entries(data);
      entries.forEach((entry) => {
        formData.append(entry[0], entry[1]);
      });
      if (Object.keys(row).length) {
        formData.append('id', row.id);
      }
      submitData(formData);
    }
  }

  return (
    <Modal show={isModal} onHide={handleCloseModal} centered>
      {
        loading
          ? <Loading />
          :
          <div>
            <Modal.Header>
              <Modal.Title>{t(title)}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {t('Submit is successed')}
                </div>
              }
              <Modal.Body>
                <div className='mb-3 text-center'>
                  <input type="file" id='image_file' className='form-control' onChange={onImageChange} accept="image/png, image/gif, image/jpeg" />
                  <input type="hidden"
                    {...register("image")}
                  />
                  {image &&
                    <img src={image} alt={t('Profile')} style={{ height: '80px' }} className="mt-2" />
                  }
                </div>
                <div className='mb-3'>
                  <label>{t('Title')}</label>
                  <input type="text" className="form-control"
                    {...register("title", { required: `${t('Field is required')}` })}
                  />
                  <div className="text-danger">{errors?.title?.message}</div>
                </div>

                <div className='mb-3'>
                  <label>{t('Expertise')}</label>
                  <Controller
                    name='expertise_id'
                    control={control}
                    render={({
                      field: { value, onChange }
                    }) => (
                      <ExpertiseDropdown
                        selected={value}
                        onChange={onChange}
                      />
                    )}
                    rules={{ required: t('Field is required') }}
                  />
                  <div className="text-danger">{errors?.expertise_id?.message}</div>
                </div>

                <div className='mb-3'>
                  <label>{t('Description')}</label>
                  <textarea className="form-control" rows={3}
                    {...register("description")}
                  />
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {t('Close')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </form>
          </div>
      }
    </Modal>
  );
}

export default BlogModal;