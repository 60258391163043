import React from "react";
import { Modal } from "react-bootstrap";
import Avatar1 from "../../assets/images/xs/avatar1.jpg";
import Avatar3 from "../../assets/images/xs/avatar3.jpg";
import Avatar4 from "../../assets/images/xs/avatar4.jpg";
import Avatar5 from "../../assets/images/xs/avatar5.jpg";
import Avatar6 from "../../assets/images/xs/avatar6.jpg";
import Avatar7 from "../../assets/images/xs/avatar7.jpg";

const NotificationsModal = (props) => {
  const { closeModal, show } = props
  const tabEvents = (e, id) => {
    e.preventDefault();
    document.getElementById("navLink1").classList.remove("active")
    document.getElementById("navLink2").classList.remove("active")
    document.getElementById("navLink3").classList.remove("active")
    document.getElementById("navLink" + id).classList.add("active")

    document.getElementById("Noti-tab-Message1").classList.remove("active")
    document.getElementById("Noti-tab-Message1").classList.add("show")
    document.getElementById("Noti-tab-Message2").classList.remove("active")
    document.getElementById("Noti-tab-Message2").classList.add("show")
    document.getElementById("Noti-tab-Message3").classList.remove("active")
    document.getElementById("Noti-tab-Message3").classList.add("show")
    document.getElementById("Noti-tab-Message" + id).classList.add("active")
    document.getElementById("Noti-tab-Message" + id).classList.add("show")
  }

  return (
    <Modal show={show} size="lg" onHide={() => { closeModal() }} scrollable animation dialogClassName="modal-dialog-vertical" >
      <Modal.Header >
        <h5 className="modal-title">Notifications <span className="badge bg-danger ms-2">14</span></h5>
        <button type="button" className="btn-close" onClick={() => { closeModal() }}></button>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-light border-bottom px-2 px-md-5 py-3">
          <ul className="nav nav-pills nav-fill" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" href="#!" id="navLink1" onClick={(e) => { tabEvents(e, 1); }}>Message</a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="#!" id="navLink2" onClick={(e) => { tabEvents(e, 2); }}>Events</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#!" id="navLink3" onClick={(e) => { tabEvents(e, 3); }}>Logs</a>
            </li>
          </ul>
        </div>
        <div className="modal-body px-md-5">
          <div className="tab-content p-0">
            <div className="tab-pane fade active show" id="Noti-tab-Message1" role="tabpanel">
              <ul className="list-unstyled list mb-0">
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar1} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Chris Fox</span> <small>2MIN</small></p>
                      <span className="text-muted">changed an issue from "In Progress" to <span className="badge bg-success">Review</span></span>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <div className="avatar rounded-circle no-thumbnail">RH</div>
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Robert Hammer</span> <small>13MIN</small></p>
                      <span className="text-muted">It is a long established fact that a reader will be distracted</span>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar3} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Orlando Lentz</span> <small>1HR</small></p>
                      <span className="text-muted">There are many variations of passages</span>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar4} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Barbara Kelly</span> <small>1DAY</small></p>
                      <span className="text-muted">Contrary to popular belief <span className="badge bg-danger">Code</span></span>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar5} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Robert Hammer</span> <small>13MIN</small></p>
                      <span className="text-muted">making it over 2000 years old</span>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar6} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Orlando Lentz</span> <small>1HR</small></p>
                      <span className="text-muted">There are many variations of passages</span>
                    </div>
                  </a>
                </li>
                <li className="py-4">
                  <a href="#!" className="d-flex">
                    <img className="avatar rounded-circle" src={Avatar7} alt="" />
                    <div className="flex-fill ms-3">
                      <p className="d-flex justify-content-between mb-0 text-muted"><span className="font-weight-bold">Rose Rivera</span> <small className="">1DAY</small></p>
                      <span className="text-muted">The generated Lorem Ipsum</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade " id="Noti-tab-Message2" role="tabpanel">
              <ul className="list-unstyled list mb-0">
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-info-circle fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Campaign <strong className="text-primary">Holiday Sale</strong> is nearly reach budget limit.</p>
                      <small className="text-muted">10:00 AM Today</small>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-thumbs-up fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Your New Campaign <strong className="text-primary">Holiday Sale</strong> is approved.</p>
                      <small className="text-muted">11:30 AM Today</small>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-pie-chart fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Website visits from Twitter is <strong className="text-danger">27% higher</strong> than last week.</p>
                      <small className="text-muted">04:00 PM Today</small>
                    </div>
                  </a>
                </li>
                <li className="py-4 border-bottom">
                  <a href="#!" className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-warning fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted"><strong className="text-warning">Error</strong> on website analytics configurations</p>
                      <small className="text-muted">Yesterday</small>
                    </div>
                  </a>
                </li>
                <li className="py-4">
                  <a href="#!" className="d-flex">
                    <div className="avatar rounded no-thumbnail"><i className="fa fa-thumbs-up fa-lg"></i></div>
                    <div className="flex-fill ms-3">
                      <p className="mb-0 text-muted">Your New Campaign <strong className="text-primary">Holiday Sale</strong> is approved.</p>
                      <small className="text-muted">11:30 AM Today</small>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-pane fade" id="Noti-tab-Message3" role="tabpanel">
              <h4>No Logs right now!</h4>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default NotificationsModal;