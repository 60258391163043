import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";
import { useForm } from "react-hook-form";
import Loading from "../Layouts/Loading";
import authService from "../../actions/authService";
import resetPassword from "../../assets/images/auth-password-reset.svg";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { register, getValues, handleSubmit, formState: { errors } } = useForm({});
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const onSubmit = async (data) => {
    setLoading(true)
    const res = await authService.passwordForgot(data);
    setResponse(res);
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="w-100 p-4 p-md-5 card border-0" style={{ maxWidth: '32rem' }}>
        <form className="row g-1 p-0 p-md-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12 text-center mb-5">
            <img src={resetPassword} className="w240 mb-4" alt="" />
            <h1>{t('Forgot Password?')}</h1>
            <span>Enter the email address you used when you joined and we'll send you instructions to reset your password.</span>
          </div>
          {
            !response.success && response.result &&
            <div className='alert alert-danger' role='alert'>{t(response.result)}</div>
          }
          {
            response.success && response.result &&
            <div className='alert alert-success' role='alert'>
              {t('Wir-geschickt').replace("<email>", getValues('email'))}
            </div>

          }
          <div className="col-12">
            <div className="mb-2">
              <label className="form-label">{t('Email address')}</label>
              <input type="email" className="form-control form-control-lg" placeholder="name@example.com"
                {...register("email", { required: `${t('Field is required')}` })}
              />
              <div className="text-danger">{errors?.email?.message}</div>
            </div>
          </div>
          <div className="col-12 text-center mt-4">
            <input className="form-control" type="hidden" value="admin" {...register("role")} />
            <button className="btn btn-lg btn-block btn-dark lift text-uppercase">{t('Submit')}</button>
          </div>
          <div className="col-12 text-center mt-4">
            <span className="text-muted"><a href="#!" onClick={(e) => { e.preventDefault(); history.push(`${process.env.PUBLIC_URL}/signin`) }}>{t('Back to Sign in')}</a></span>
          </div>
        </form>
      </div>
  )
}

export default ForgotPassword;