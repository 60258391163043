import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import Loading from '../../Layouts/Loading';
import sliderService from '../../../actions/sliderService';
import SliderModal from './SliderModal';
import { backendUrl } from '../../../constants';

const PageContainer = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [data, setData] = useState([]);
  const [response, setResponse] = useState({})
  const [title, setTitle] = useState();
  const [row, setRow] = useState({});

  const getAllSilders = async () => {
    setLoading(true);
    const res = await sliderService.getAllSliders();
    if (res.success && res.result) setData(res.result);
    setLoading(false);
  }

  useEffect(() => {
    getAllSilders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseModal = () => {
    setIsModal(false);
  }

  const handleUpdate = (item) => {
    let _data = [...data]
    const found = data.find(obj => obj.id === item.id);
    if(found){
      _data = _data.map(obj => {
        if (obj.id === item.id) {
          return item
        }
        return obj
      })
      setData(_data)
    } else {
      _data.push(item)
      setData(_data)
      setResponse({success: true, result: 'added' });
      setIsModal(false);
    }
  }

  const addRow = () => {
    setRow({})
    setTitle('Add New Slider')
    setIsModal(true);
  }

  const deleteRow = async(id) => {
    setLoading(true)
    const res = await sliderService.deleteSlider({ id });
    if (res.success && res.result) {
      const newData = data.filter((item) => item.id !== id);
      setData(newData)
    }
    setResponse(res)
    setLoading(false)
  }

  const editRow = (id) => {
    const found = data.find(obj => obj.id === id);
    setRow(found)
    setTitle('Edit Slider')
    setIsModal(true);
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body datableBody">
          <div className='card-title mb-4'><h4>{t('All Sliders')}</h4></div>
          <button className='btn btn-primary mb-3' onClick={addRow}>{t('Add New Slider')}</button>
          {
            !response.success && response.result &&
            <div className='alert alert-danger d-flex align-items-center' role='alert'>
              {t(response.result)}
            </div>
          }
          {
            response.success && response.result &&
            <div className='alert alert-success d-flex align-items-center' role='alert'>
              {response.result === 'added' ? t('Submit is successed') : t('Deleting is successed!')}
            </div>
          }
          <div className='overflow-auto'>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{t('Title')}</th>
                  <th>{t('Status')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0
                  ? data.map(item =>
                    <tr key={item.id} style={{ verticalAlign: 'middle' }}>
                      <td><img src={backendUrl + '/images/sliders/' + item.image + '?v='+ new Date().getTime()} style={{ height: '40px' }} alt={item.title}/></td>
                      <td>{item?.title}</td>
                      <td>
                        {item.active === 0 || item.active === "0"
                          ? <span className="badge bg-warning">{t('Inactive')}</span>
                          : <span className="badge bg-primary">{t('Active')}</span>
                        }
                      </td>
                      <td className='text-nowrap text-end'>
                        <button className='btn btn-primary me-1 btn-sm' onClick={() => editRow(item.id)}><i className="fa fa-pencil-square-o"></i></button>
                        <button className='btn btn-danger btn-sm' onClick={() => deleteRow(item.id)}><i className="fa fa-trash-o"></i></button>
                      </td>
                    </tr>
                  )
                  :
                  <tr>
                    <td colSpan={4} className="text-center">{t('No available data')}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        {isModal &&
          <SliderModal
            isModal={isModal}
            handleCloseModal={handleCloseModal}
            title={title}
            row={row}
            handleUpdate={handleUpdate}
          />
        }

      </div>

  )
}

export default PageContainer;