import React from 'react';
import PageContainer from '../../components/Doctors/EditDoctor/PageContainer'
import CheckLoggedin from '../../components/Auth/CheckLoggedin';

const EditDoctor = () => {
  return (  
    <CheckLoggedin>
    <div className="container">
      <PageContainer />
    </div>
    </CheckLoggedin>
  );
}
 
export default EditDoctor;