import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DataTable from "react-data-table-component";
import { useSelector } from 'react-redux';
import { useTranslation } from '../../hooks/useTranslation';
import transactionService from '../../actions/transactionService';
import invoiceDesginService from '../../actions/invoiceDesginService';
import Loading from '../Layouts/Loading'
import { backendUrl } from '../../constants';

const PageContainer = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([])
  const [response, setResponse] = useState({})
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const { auth } = useSelector(store => store.auth)
  const { configs } = useSelector(store => store.configs)
  const [invSetting, setInvSetting] = useState({})

  const getTransactions = async () => {
    setLoading(true)
    const res = await transactionService.getAllTransactions();
    if(res.success&&res.result) setTransactions(res.result);
    const res_inv = await invoiceDesginService.getInvoiceDesign();
    setInvSetting(res_inv.result)
    setLoading(false)
  }

  const deleteTransaction = async (id) => {
    setLoading(true)
    const res = await transactionService.deleteTransaction({ token: auth.token, id });
    if(res.success && res.result){
      const newData = transactions.filter(item => item.id !== id)
      setTransactions(newData)
    }
    setResponse(res)
    setLoading(false)
  }

  const approveTransaction = async (id)  => {
    const res = await transactionService.approveTransaction({ token: auth.token, id });
    if(res.success && res.result){
      const newData = transactions.map(item =>{
        if(item.id === id){
          return {
            ...item,
            status: 1
          }
        }
        return item
      })
      setTransactions(newData)
    }
  }

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    const result = transactions.filter((item) => {
      if(search==='') return item;
      return item?.user?.first_name?.toLowerCase().includes(search?.toLowerCase())
          || item?.user?.last_name?.toLowerCase().includes(search?.toLowerCase());
    })
    setData(result);
  }, [transactions, search])

  const columns = [
    {
      name: t('User Name'),
      selector: (row) => row?.user?.first_name + ' ' + row?.user?.last_name,
      sortable: true,
    },
    {
      name: t('Product'),
      selector: (row) => t(row?.product,)
    },
    {
      name: t('Payment method'),
      selector: (row) => t(row?.payment_method),
    },
    {
      name: t('Price'),
      selector: (row) => row?.price + configs?.currency_symbol,
    },
    {
      name: t('Status'),
      selector: (row) => {
        if(row.status === 0) return <span className="badge bg-warning">{t('Pending')}</span>
        if(row.status === 1) return <span className="badge bg-success">{t('Approved')}</span>
      },
    },
    {
      name: t('Transaction Date'),
      selector: (row) => moment(row?.created_at).format('yyyy.MM.DD'),
    },
    {
      name: t('Activation Date'),
      selector: (row) => {
        if(row.status === 1) return moment(row?.updated_at).format('yyyy.MM.DD'); else return '';
      },
    },
    {
      name: t('Invoice'),
      cell: row => (
        <a href={backendUrl + '/invoices/' + invSetting?.inv_num_prefix + row?.id + invSetting?.inv_num_suffix + '.pdf'} className='btn btn-primary btn-sm me-1' target="_blank">{'PDF ' + t('Invoice')}</a> 
      )
    },
    {
      name: '',
      cell: row => (
        <div style={{textAlign: "right", width: '100%'}}>
          { row.status === 0 && 
              <button className='btn btn-success btn-sm me-1' onClick={() => approveTransaction(row.id)}>{t('Approve')}</button> 
          }
          <button className='btn btn-danger btn-sm' onClick={() => deleteTransaction(row.id)}><i className="fa fa-trash-o"></i></button>
        </div>
      )
    }
  ];

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body datableBody">
          {
            !response.success && response.result &&
            <div className='alert alert-danger d-flex align-items-center' role='alert'>
              {t(response.result)}
            </div>
          }
          {
            response.success && response.result &&
            <div className='alert alert-success d-flex align-items-center' role='alert'>
              {t('Doctor is deleted successfully')}
            </div>
          }
          <DataTable
            title={t('Transactions')}
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input type="text" placeholder={t('search here')} className='w-25 form-control'
                value={search}
                onChange = {(e) => setSearch(e.target.value) }
              />
            }
          />
        </div>
      </div>
  );
}

export default PageContainer;