import React, { useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Layouts/Loading';
import { updateConfigs, reset } from '../../../reducers/configs';
import { useForm, Controller } from "react-hook-form";
import Switch from "react-switch";

const PageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector(store => store.auth)
  const { configs, error, message, loading } = useSelector(store => store.configs)
  const { handleSubmit, setValue, register, control } = useForm({})

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setValue('fieldArray', configs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs, message])

  const onSubmit = (data) => {
    const newData = data?.fieldArray
    newData.token = auth.token
    dispatch(updateConfigs(data?.fieldArray))
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Payment Settings')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              error && message &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(message)}
              </div>
            }
            {
              !error && message &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Changing is successed')}
              </div>

            }
            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('Currency abbreviation')}:
              </label>
              <div className='col-lg-3 col-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.currency_symbol")}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('Currency ISO-Code')}:
              </label>
              <div className='col-lg-3 col-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.currency_name")}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('VAT') + '(%)'}:
              </label>
              <div className='col-lg-3 col-4'>
                <input type="text" className='form-control'
                  {...register("fieldArray.vat")}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('Paypal')}:
              </label>
              <div className='col-lg-3 col-4 pt-2'>
                <Controller
                  name='fieldArray.paypal_act'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={value === "true" || value === true ? true : false}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    {t('Client ID')}
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.paypal_clientId")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('Stripe')}:
              </label>
              <div className='col-lg-3 col-4 pt-2'>
                <Controller
                  name='fieldArray.stripe_act'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={value === "true" || value === true ? true : false}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    SEC
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.stripe_sec")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    PUB
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.stripe_pub")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('Transfer')}:
              </label>
              <div className='col-lg-3 col-4 pt-2'>
                <Controller
                  name='fieldArray.bank_act'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={value === "true" || value === true ? true : false}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    {t('Account')}
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.bank_name")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    BIC
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.bank_bic")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-3'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    IBAN
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.bank_iban")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
                {t('Payrexx')}:
              </label>
              <div className='col-lg-3 col-4 pt-2'>
                <Controller
                  name='fieldArray.payrexx_act'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={value === "true" || value === true ? true : false}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    {t('Instance')}
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.payrexx_instance")}
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4'>
              <label className='col-lg-3 col-6 col-form-label mobile-left'>
              </label>
              <div className='col-lg-5'>
                <div className="input-group">
                  <span className="input-group-text border-0 px-4">
                    {t('API')}
                  </span>
                  <input type="text" className="form-control"
                    {...register("fieldArray.payrexx_api")}
                  />
                </div>
              </div>
            </div>

            <div className='text-center mt-2'>
              <input type="submit" className='btn btn-primary' value={t('Submit')} />
            </div>

          </form>
        </div>
      </div>
  );
}

export default PageContainer;