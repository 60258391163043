import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import { useTranslation as useMyTranslation } from "../../../hooks/useTranslation";

const LanguageDropdown = (props) => {
  const { dropToggleClass } = props;
  const { i18n } = useTranslation();
  const { configs } = useSelector(store => store.configs)
  const { t } = useMyTranslation();
  const currentLng = localStorage.getItem('i18nextLng') || 'de';

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    window.location.reload();
  };

  const cln = currentLng === 'en' ? 'flag-icon-gb'
            : currentLng === 'de' ? 'flag-icon-de'
            : currentLng === 'sp' ? 'flag-icon-es'
            : currentLng === 'it' ? 'flag-icon-it'
            : currentLng === 'pr' ? 'flag-icon-pt'
            : currentLng === 'fr' ? 'flag-icon-fr'
            : currentLng === 'gr' ? 'flag-icon-gr'
            : 'flag-icon-sa';

  return (
    <Dropdown as="div" className="Language me-2">
      <Dropdown.Toggle variant="" id="dropdown-basic" className={dropToggleClass ? dropToggleClass : "nav-link text-primary pulse"}>
        <i className={`flag-icon ${cln}`}></i>
      </Dropdown.Toggle>
      <Dropdown.Menu as="div" className="rounded-lg shadow border-0 p-0 show " >
        <div className="card border-0">
          <div className="list-group list-group-custom" style={{ width: 200 }}>
            {configs.en === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('en')}>
                <span className="flag-icon flag-icon-gb me-2"></span>{t('English')}
              </div>
            }
            {configs.de === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('de')}>
                <span className="flag-icon flag-icon-de me-2"></span>{t('German')}
              </div>
            }
            {configs.sp === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('sp')}>
                <span className="flag-icon flag-icon-es me-2"></span>{t('Spanish')}
              </div>
            }
            {configs.it === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('it')}>
                <span className="flag-icon flag-icon-it me-2"></span>{t('Italian')}
              </div>
            }
            {configs.pr === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('pr')}>
                <span className="flag-icon flag-icon-pt me-2"></span>{t('Portuguese')}
              </div>
            }
            {configs.fr === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('fr')}>
                <span className="flag-icon flag-icon-fr me-2"></span>{t('French')}
              </div>
            }
            {configs.gr === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('gr')}>
                <span className="flag-icon flag-icon-gr me-2"></span>{t('Greek')}
              </div>
            }
            {configs.ar === 'yes' &&
              <div className="list-group-item" onClick={() => changeLanguage('ar')}>
                <span className="flag-icon flag-icon-sa me-2"></span>{t('Arabic')}
              </div>
            }
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageDropdown;