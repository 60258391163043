import React from "react";
import { connect } from "react-redux";
import { SketchPicker } from 'react-color';
import { Switch, withRouter } from "react-router-dom";
import NotificationsModal from "./components/Layouts/NotificationsModal";
import SettingsModal from "./components/Layouts/SettingsModal";
import MainRouters from "./components/Layouts/MainRouters";
import AuthIndex from "./screens/AuthIndex";
import Footer from './components/Layouts/Footer'
import { onChangeGradient, openSettingModal, openNotificationModal, openSearchModal, onEnableSidebarBack, activeIdSidebarBack } from "./actions";
import { fetchAll as configFetchAll } from "./reducers/configs";
import { getTexts } from "./reducers/texts";
import LayoutLPageHeader from "./components/Layouts/LayoutLPageHeader";
import LayoutQHeader from "./components/Layouts/LayoutQHeader";
import LayoutQPageHeader from "./components/Layouts/LayoutQPageHeader";
import Loading from "./components/Layouts/Loading";


class App extends React.Component {

  state = {
    PrimaryColor: "",
    isShowColorPicker: false,
    colorType: "",
    isChangeRoute: false,
    loading: false
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const res = await this.props.getTexts();
    await this.props.configFetchAll();
    this.setState({ loading: false })
    if(!res.payload){
      alert('Fetching data is failed. Check your network or backend server and try again!')
    }
    document.body.classList.add("font-opensans");
    if (document.getElementById("layout-a")) {
      document.getElementById("layout-a").classList.add("theme-blue");
    }
    const { auth } = this.props?.auth
    if (auth?.role !== 'admin') {
      this.props.history.push(`${process.env.PUBLIC_URL}/signin`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.auth !== this.props?.auth) {
      if (this.props?.auth?.auth?.role !== 'admin') {
        this.props.history.push(`${process.env.PUBLIC_URL}/signin`);
      } else {
        if (this.props.history.location.pathname === '/signin') {
          this.props.history.push(`${process.env.PUBLIC_URL}/`);
        }
      }
    }
  }

  activekey() {
    var res = window.location.pathname
    var baseUrl = process.env.PUBLIC_URL
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    // res = res.length > 1 ? res[res.length-1] : "/";
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? "/" + res : "/";;
    const activeKey1 = res;
    return activeKey1
  }

  render() {
    const { isGradient, isSettingModal, isNotificationModal, isLocationChanged, isBackgroundImage, activatedSidebarBack } = this.props.dashboardReducer;
    if (this.activekey() === "/signin" || this.activekey() === "/forgotpassword") {
      return <div id="layout-q" className={isLocationChanged ? "" : "theme-green"}>
        <div className="main auth-div p-2 py-3 p-xl-5">
          <Switch>
            <AuthIndex />
          </Switch>
        </div>
      </div>
    }

    return (
      this.state.loading ?
        <Loading />
        :
        <div id="layout-q" className={isLocationChanged ? "" : "theme-green"}>

          <LayoutQHeader onClickSetting={() => { this.props.openSettingModal(true) }} />
          <LayoutQPageHeader activekey={this.activekey()} />

          <div className="main px-xl-5 px-lg-4 px-md-3">
            <LayoutLPageHeader activekey={this.activekey()} />
            <Switch>
              <MainRouters />
            </Switch>
          </div>

          <Footer />

          <NotificationsModal
            show={isNotificationModal}
            closeModal={() => { this.props.openNotificationModal(false); }}
          />
          <SettingsModal
            activeId="layout-q"
            onChangeGradient={() => { this.props.onChangeGradient(!isGradient) }}
            onClickPrimaryColor={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "Primary" }); }, 10); }}
            onClickouter={() => { this.setState({ isShowColorPicker: false, colorType: "" }); }}
            onClickSecondaryColor={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "Secondary" }); }, 10); }}
            onClickchartColorPicker1={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "chartColorPicker1" }); }, 10); }}
            onClickchartColorPicker2={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "chartColorPicker2" }); }, 10); }}
            onClickchartColorPicker3={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "chartColorPicker3" }); }, 10); }}
            onClickchartColorPicker4={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "chartColorPicker4" }); }, 10); }}
            onClickchartColorPicker5={() => { setTimeout(() => { this.setState({ isShowColorPicker: true, colorType: "chartColorPicker5" }); }, 10); }}
            onEnableSidebarBack={() => { this.props.onEnableSidebarBack(!isBackgroundImage); }}
            isGradient={isGradient}
            show={isSettingModal}
            isBackgroundImage={isBackgroundImage}
            activatedSidebarBack={activatedSidebarBack}
            closeModal={() => { this.props.openSettingModal(false); this.setState({ isShowColorPicker: false, colorType: "" }); }}
            activeIdSidebarBack={(n) => { this.props.activeIdSidebarBack(n); }}
            colourPicker={() => {
              return <div className={`color-picker-dom ${this.state.isShowColorPicker ? "d-block" : ""}`}>
                <SketchPicker
                  onChange={(e) => {
                    this.setState({ PrimaryColor: e.hex });
                    switch (this.state.colorType) {
                      case "Primary":
                        document.documentElement.style.setProperty('--primary-color', e.hex);
                        break;

                      case "Secondary":
                        document.documentElement.style.setProperty('--secondary-color', e.hex);
                        break;

                      case "chartColorPicker1":
                        document.documentElement.style.setProperty('--chart-color1', e.hex);
                        break;

                      case "chartColorPicker2":
                        document.documentElement.style.setProperty('--chart-color2', e.hex);
                        break;

                      case "chartColorPicker3":
                        document.documentElement.style.setProperty('--chart-color3', e.hex);
                        break;

                      case "chartColorPicker4":
                        document.documentElement.style.setProperty('--chart-color4', e.hex);
                        break;

                      case "chartColorPicker5":
                        document.documentElement.style.setProperty('--chart-color5', e.hex);
                        break;

                      default:
                        return null;
                    }

                  }} color={this.state.PrimaryColor} />
              </div>

            }}
          />
        </div>
    );
  }
}

const mapStateToProps = ({ texts, auth, configs, dashboardReducer }) => ({
  texts,
  auth,
  configs,
  dashboardReducer
});

export default withRouter(connect(
  mapStateToProps,
  {
    onChangeGradient,
    openSettingModal,
    openNotificationModal,
    openSearchModal,
    onEnableSidebarBack,
    activeIdSidebarBack,
    configFetchAll,
    getTexts
  }
)(App));