import React from 'react';
import {  Route, Redirect } from "react-router-dom";
import Dashboard from '../../screens/Dashboard'
import AllDoctors from '../../screens/Doctors/AllDoctors'
import AddDoctor from '../../screens/Doctors/AddDoctor'
import EditDoctor from '../../screens/Doctors/EditDoctor'
import AllPatients from '../../screens/Patients/AllPatients';
import AddPatient from '../../screens/Patients/AddPatient';
import EditPatient from '../../screens/Patients/EditPatient';
import AdminProfile from '../../screens/Settings/AdminProfile';
import EmailSetting from '../../screens/Settings/EmailSetting';
import EmailEdit from '../../screens/Settings/EmailEdit';
import GoogleSetting from '../../screens/Settings/GoogleSetting';
import CountrySetting from '../../screens/Settings/CountrySetting';
import ExpertiseSetting from '../../screens/Settings/ExpertiseSetting';
import TitleSetting from '../../screens/Settings/TitleSetting';
import GroupSetting from '../../screens/Settings/GroupSetting';
import PaymentSetting from '../../screens/Settings/PaymentSetting';
import SiteSetting from '../../screens/Settings/SiteSetting';
import HomeSlider from '../../screens/HomePage/HomeSlider';
import Services from '../../screens/Settings/Services';
import Reasons from '../../screens/Settings/Reasons';
import DoctorTypes from '../../screens/Settings/DoctorTypes';
import Pages from '../../screens/Pages';
import CookieSettings from '../../screens/HomePage/CookieSettings';
import WorkProcess from '../../screens/HomePage/WorkProcess';
import Blogs from '../../screens/HomePage/Blogs';
import Feedback from '../../screens/HomePage/Feedback';
import Transactions from '../../screens/Transactions'
import InvoiceDesign from '../../screens/Invoices/InvoiceDesign'
import InvoiceComment from '../../screens/Invoices/InvoiceComment'
import InvoiceFooter from '../../screens/Invoices/InvoiceFooter'

const MainRouters = () => {
  return (
    <>
      <Route exact path={`${process.env.PUBLIC_URL}/`}>
        <Redirect to="/dashboard" />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />
      <Route path={`${process.env.PUBLIC_URL}/all_doctors`} component={AllDoctors} />
      <Route path={`${process.env.PUBLIC_URL}/add_doctor`} component={AddDoctor} />
      <Route path={`${process.env.PUBLIC_URL}/doctor/:id`} component={EditDoctor} />
      <Route path={`${process.env.PUBLIC_URL}/all_patients`} component={AllPatients} />
      <Route path={`${process.env.PUBLIC_URL}/add_patient`} component={AddPatient} />
      <Route path={`${process.env.PUBLIC_URL}/patient/:id`} component={EditPatient} />
      <Route path={`${process.env.PUBLIC_URL}/profilepage`} component={AdminProfile} />
      <Route path={`${process.env.PUBLIC_URL}/email_settings`} component={EmailSetting} />
      <Route path={`${process.env.PUBLIC_URL}/mail_edit/:name`} component={EmailEdit} />
      <Route path={`${process.env.PUBLIC_URL}/google_settings`} component={GoogleSetting} />
      <Route path={`${process.env.PUBLIC_URL}/country_settings`} component={CountrySetting} />
      <Route path={`${process.env.PUBLIC_URL}/title_setting`} component={TitleSetting} />
      <Route path={`${process.env.PUBLIC_URL}/expertise_settings`} component={ExpertiseSetting} />
      <Route path={`${process.env.PUBLIC_URL}/group_setting`} component={GroupSetting} />
      <Route path={`${process.env.PUBLIC_URL}/payment_settings`} component={PaymentSetting} />
      <Route path={`${process.env.PUBLIC_URL}/site_settings`} component={SiteSetting} />
      <Route path={`${process.env.PUBLIC_URL}/home_slider`} component={HomeSlider} />
      <Route path={`${process.env.PUBLIC_URL}/services`} component={Services} />
      <Route path={`${process.env.PUBLIC_URL}/reasons`} component={Reasons} />
      <Route path={`${process.env.PUBLIC_URL}/pages/:page`} component={Pages} />
      <Route path={`${process.env.PUBLIC_URL}/cookie_settings`} component={CookieSettings} />
      <Route path={`${process.env.PUBLIC_URL}/work_process`} component={WorkProcess} />
      <Route path={`${process.env.PUBLIC_URL}/blogs`} component={Blogs} />
      <Route path={`${process.env.PUBLIC_URL}/feedback`} component={Feedback} />
      <Route path={`${process.env.PUBLIC_URL}/doctor_types`} component={DoctorTypes} />
      <Route path={`${process.env.PUBLIC_URL}/transactions`} component={Transactions} />
      <Route path={`${process.env.PUBLIC_URL}/invoice_design`} component={InvoiceDesign} />
      <Route path={`${process.env.PUBLIC_URL}/invoice_comment`} component={InvoiceComment} />
      <Route path={`${process.env.PUBLIC_URL}/invoice_footer`} component={InvoiceFooter} />
      
    </>
  );
}

export default MainRouters;