import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { backendUrl } from '../constants';
import axios from 'axios'

export const fetchAll = createAsyncThunk(
  'configs/fetchAll',
  async () => {
    try {
      const response = await axios.get(backendUrl + '/api/get_configs')
      const entries = Object.entries(response?.data?.result);
      let data = {};
      entries.forEach((entry) => {
        data[entry[1].key] = entry[1].val;
      });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateConfigs = createAsyncThunk(
  'configs/updateConfigs',
  async (data) => {
    try {
      const response = await axios.post(backendUrl + '/api/update_configs', data, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      return response.data
    } catch (err) {
      if (err.response?.data?.result) {
        return err.response.data
      } else {
        return {
          success: false,
          result: err.message
        };
      }
    }
  }
);


export const configsSlice = createSlice({
  name: 'configs',
  initialState: {
    configs: [],
    loading: false,
    error: false,
    message: ''
  },

  reducers: {
    reset: (state) => {
      state.loading = false
      state.error = false
      state.message = ''
    },
  },

  extraReducers: {

    // ========== fetch All ==================================
    [fetchAll.pending]: (state, action) => {
      state.loading = true
    },
    [fetchAll.fulfilled]: (state, action) => {
      state.error = false
      state.message = ''
      state.loading = false
      state.configs = action.payload;
    },
    [fetchAll.rejected]: (state, action) => {
      state.error = true
      state.message = 'faild'
      state.loading = false
    },

    // ========== fetch All ==================================
    [updateConfigs.pending]: (state, action) => {
      state.loading = true
    },
    [updateConfigs.fulfilled]: (state, action) => {
      state.loading = false
      if (action.payload?.success) {
        state.error = false
        state.message = 'success'
        state.configs =  { ...state.configs, ...action.payload.result };
      } else {
        state.error = true
        state.message = 'token';
        state.auth = null;
      }
    },
    [updateConfigs.rejected]: (state, action) => {
      state.error = true
      state.message = 'faild'
      state.loading = false
    }

  },
});

export const { reset } = configsSlice.actions

export default configsSlice.reducer;
