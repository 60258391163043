import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const userLogin = async (userData) => {
  try {
    const response = await axios.post(backendUrl + '/api/login/'+currentLng, userData);
    const auth = response.data.result;
    auth.token = response.data.token;
    localStorage.setItem('admin', JSON.stringify(auth));
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const userLogout = async (userToken) => {
  await axios.post(backendUrl + '/api/logout', userToken);
  localStorage.removeItem('admin');
  return {
    success: true,
    result: 'success'
  };
}

const userRegister = async (userData) => {
  try {
    const response = await axios.post(backendUrl + '/api/register/' + currentLng, userData);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const activeEmail = async (time, id) => {
  try {
    const response = await axios.get(backendUrl + '/api/active_email/' + time + '/' + id);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const passwordForgot = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/password_forgot/' + currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const deleteUser = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/delete_user', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const userCreate = async (userData) => {
  try {
    const response = await axios.post(backendUrl + '/api/user_create', userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const userUpdate = async (userData) => {
  try {
    const response = await axios.post(backendUrl + '/api/user_update/'+currentLng, userData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const getUser = async (id) => {
  try {
    const response = await axios.get(backendUrl + '/api/get_user/'+id+'/'+currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const checkLoggedin = async (token) => {
  try {
    const response = await axios.get(backendUrl + '/api/check_loggedin', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const authService = {
  userRegister,
  userLogin,
  userLogout,
  activeEmail,
  passwordForgot,
  deleteUser,
  userCreate,
  userUpdate,
  getUser,
  checkLoggedin
}

export default authService