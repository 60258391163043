import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from "react-bootstrap";
import { IconPicker } from 'react-fa-icon-picker'
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm, Controller } from "react-hook-form";
import stepService from '../../../actions/stepService';
import Loading from '../../Layouts/Loading';

const StepModal = (props) => {
  const { isModal, handleCloseModal, row, handleUpdate } = props;
  const { t } = useTranslation()
  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector(store => store.auth)

  const { handleSubmit, register, control, formState: { errors } } = useForm({
    defaultValues: {
      title: row?.title || '',
      icon: row?.icon || '',
      description: row?.description || '',
    }
  })

  const onSubmit = async (data) => {
    data.token = auth.token
    const res = await stepService.updateStep(data)
    if (res.success && res.result) handleUpdate(res.result)
    setResponse(res)
    setLoading(false)
  }

  return (
    <Modal show={isModal} onHide={handleCloseModal} centered>
      {
        loading
          ? <Loading />
          :
          <div>
            <Modal.Header>
              <Modal.Title>{t('Edit Step')}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {t('Submit is successed')}
                </div>
              }
              <Modal.Body>
                <div className='row mb-3'>
                  <div className='col-lg-4'>{t('Select Icon')}:</div>
                  <div className='col-lg-8'>
                    <Controller
                      name='icon'
                      control={control}
                      render={({
                        field: { value, onChange }
                      }) => (
                        <IconPicker
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  <input type="hidden" value={row.id} {...register("id")} />
                </div>
                <div className='mb-3'>
                  <label>{t('Title')}</label>
                  <div className='mb-2'>
                    <input type="text" className="form-control"
                      {...register("title", { required: `${t('Field is required')}` })}
                    />
                    <div className="text-danger">{errors?.title?.message}</div>
                  </div>
                </div>

                <div className='mb-3'>
                  <label>{t('Description')}</label>
                  <textarea className="form-control" rows={3}
                    {...register("description")}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {t('Close')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </form>
          </div>
      }
    </Modal>
  );
}

export default StepModal;