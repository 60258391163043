import React, { useEffect, useState } from 'react';
import Switch from "react-switch";
import { useTranslation } from '../../../hooks/useTranslation';
import doctorTypeService from '../../../actions/doctorTypeService';
import { useForm, Controller } from "react-hook-form";
import Loading from '../../Layouts/Loading';

const PageContainer = () => {
  const { t } = useTranslation()
  const { handleSubmit, setValue, register, control } = useForm({})
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})

  const getAllDoctorTypes = async () => {
    setLoading(true)
    const res = await doctorTypeService.getAllDoctorTypes()
    setData(res.result)
    setLoading(false)
  }

  useEffect(() => {
    getAllDoctorTypes()
  }, [])

  useEffect(() => {
    setValue('fieldArray', data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSubmit = async (saveData) => {
    setLoading(true)
    const res = await doctorTypeService.updateDoctorTypes(saveData);
    setData(saveData.fieldArray);
    setResponse(res)
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Doctor types')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              !response.success && response.result &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(response.result)}
              </div>
            }
            {
              response.success && response.result &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Submit is successed')}
              </div>
            }

            <div className='row mb-2'>
              <div className='col-lg-3'></div>
              <div className='col-lg-3'><b>{t(data[0]?.name)}</b></div>
              <div className='col-lg-3'><b>{t(data[1]?.name)}</b></div>
              <div className='col-lg-3'><b>{t(data[2]?.name)}</b></div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Price')}:</div>
              <div className='col-lg-3'>
                <input type="text" className="form-control"
                  {...register("fieldArray[0][price]")}
                />
              </div>
              <div className='col-lg-3'>
                <input type="text" className="form-control"
                  {...register("fieldArray[1][price]")}
                />
              </div>
              <div className='col-lg-3'>
                <input type="text" className="form-control"
                  {...register("fieldArray[2][price]")}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Description')}:</div>
              <div className='col-lg-3'>
                <textarea className="form-control"
                  {...register("fieldArray[0][text]")}
                ></textarea>
                <input type="hidden"
                  {...register("fieldArray[0][id]")}
                />
              </div>
              <div className='col-lg-3'>
                <textarea className="form-control"
                  {...register("fieldArray[1][text]")}
                ></textarea>
                <input type="hidden"
                  {...register("fieldArray[1][id]")}
                />
              </div>
              <div className='col-lg-3'>
                <textarea className="form-control"
                  {...register("fieldArray[2][text]")}
                ></textarea>
                <input type="hidden"
                  {...register("fieldArray[2][id]")}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Change Personal Information')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][personal_info]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][personal_info]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][personal_info]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Get notified and comment on new reviews')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][notify]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][notify]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][notify]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Publish performance overview in profile')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][publish_overview]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][publish_overview]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][publish_overview]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Prominent presence on Google thanks to Google My Business')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][google_presence]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][google_presence]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][google_presence]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Individual doctor profile with portrait photo, practice photos, detailed content')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][individual_profile]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][individual_profile]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][individual_profile]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Link to your practice website')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][website]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][website]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][website]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Top visibility on Google thanks to Google My Business')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][google_top]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][google_top]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][google_top]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Professional profile service')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][profile_service]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][profile_service]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][profile_service]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Online appointment scheduling')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][online_appointment]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][online_appointment]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][online_appointment]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Powerful appointment calendar')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][appointment_calendar]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][appointment_calendar]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][appointment_calendar]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('KBV-certified video consultation')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][kbv]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][kbv]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][kbv]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Individual support by personal contacts')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][personal_contact]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][personal_contact]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][personal_contact]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Online presence on over 40 portals')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][portals40]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][portals40]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][portals40]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Prominent ad space for your 5 desired treatments')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][treatments5]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][treatments5]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][treatments5]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Profile presence on the homepage')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][homepage_profile]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][homepage_profile]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][homepage_profile]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <div className='col-lg-3'>{t('Publish practice videos on your profile')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][profile_video]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][profile_video]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][profile_video]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-lg-3'>{t('SEO-optimized text creation by editors')}:</div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[0][seo_edit]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[1][seo_edit]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
              <div className='col-lg-3 pt-1'>
                <Controller
                  name='fieldArray[2][seo_edit]'
                  control={control}
                  render={({
                    field: { value, onChange }
                  }) => (
                    <Switch
                      onChange={onChange}
                      checked={!!value}
                      width={50}
                      height={20}
                    />
                  )}
                />
              </div>
            </div>

            <div className='text-center mt-2'>
              <input type="submit" className='btn btn-primary' value={t('Submit')} />
            </div>
          </form>
        </div>
      </div>
  );
}

export default PageContainer;