import React from "react";
import PageContainer from "../components/Pages/PageContainer";
import CheckLoggedin from "../components/Auth/CheckLoggedin";

const Pages = () => {
  return (
    <CheckLoggedin>
      <div className="container">
        <PageContainer />
      </div>
    </CheckLoggedin>
  )
}

export default Pages;