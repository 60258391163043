import React, { useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../Layouts/Loading';
import { updateConfigs, reset } from '../../../reducers/configs';
import { useForm } from "react-hook-form";

const PageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit,setValue, register } = useForm({})
  const { configs, error, message, loading } = useSelector(store => store.configs)
  const { auth } = useSelector(store => store.auth)

  useEffect(()=>{
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    setValue('google_map_api', configs?.google_map_api)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs,message])

  const onSubmit = (data) => {
    data.token = auth.token;
    dispatch(updateConfigs(data))
  }


  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Google Settings')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              error && message &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(message)}
              </div>
            }
            {
              !error && message &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Changing is successed')}
              </div>

            }
            <div className='row mb-2'>
              <label className='col-lg-3 col-form-label mobile-left'>
                Google Map API:
              </label>
              <div className='col-lg-6'>
                <input type="text" className='form-control'
                  {...register("google_map_api")}
                />
              </div>
            </div>
            <div className='text-center'>
              <button type="submit" className="btn btn-primary me-2">{t('Submit')}</button>
            </div>
          </form>
        </div>
      </div>
  );
}

export default PageContainer;