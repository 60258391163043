import React from "react";
import { useTranslation } from "../../../hooks/useTranslation";

const MainSearchSlideDown = () => {
  const { t } = useTranslation()
  return (
    <div className="main-search border-start px-3 flex-fill">
      <input className="form-control" type="text" placeholder={t('Enter your search key word')} />
    </div>
  )
}

export default MainSearchSlideDown;