import React from 'react';
import styles from './style.module.scss';
import loader from '../../../assets/images/loader.svg'
const Loading = () => {
  return ( 
    <div className={styles.loadingContainer}>
      <img src={loader} alt="loading"/>
    </div>
  );
}
 
export default Loading;