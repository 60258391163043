import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { IconPicker } from 'react-fa-icon-picker'
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import Loading from '../../Layouts/Loading';
import servicesService from '../../../actions/servicesService';
import { backendUrl } from '../../../constants';

const ServiceModal = (props) => {
  const { isModal, handleCloseModal, title, row, handleUpdate } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})
  const [selectedFile, setSelectedFile] = useState()
  const [icon, setIcon] = useState(row?.icon ? row?.icon : '')
  const [image, setImage] = useState(row?.image ? backendUrl + '/images/services/' + row.image : '')
  const { auth } = useSelector(store => store.auth)
  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      title: row?.title || '',
      description: row?.description || '',
      slug: row?.slug || '',
      image: row?.image || '',
    }
  })

  const onImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file)
    setImage(URL.createObjectURL(file));
  };

  const submitData = async (data) => {
    setLoading(true)
    let res;
    if (Object.keys(row).length) {
      res = await servicesService.updateService(data);
    } else {
      res = await servicesService.createService(data);
    }

    if (res.success && res.result) handleUpdate(res.result)
    setResponse(res)
    setLoading(false)
  }

  const onSubmit = (data) => {
    if (!image) {
      setResponse({ success: false, result: 'Please select an image' });
    } else {
      const formData = new FormData();
      formData.append('token', auth.token);
      formData.append('image_file', selectedFile);
      formData.append('icon', icon);
      const entries = Object.entries(data);
      entries.forEach((entry) => {
        formData.append(entry[0], entry[1]);
      });
      if (Object.keys(row).length) {
        formData.append('id', row.id);
      }
      submitData(formData);
    }
  }


  return (
    <Modal show={isModal} onHide={handleCloseModal} centered>
      {
        loading
          ? <Loading />
          :
          <div>
            <Modal.Header>
              <Modal.Title>{t(title)}</Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {t('Submit is successed')}
                </div>
              }
              <Modal.Body>
                <div className='mb-3 text-center'>
                  <input type="file" id='image_file' className='form-control' onChange={onImageChange} accept="image/png, image/gif, image/jpeg" />
                  <input type="hidden"
                    {...register("image")}
                  />
                  {image &&
                    <img src={image} alt={t('Profile')} style={{ height: '80px' }} className="mt-2" />
                  }
                </div>
                <div className='row mb-3'>
                  <div className='col-lg-4'>{t('Select Icon')}:</div>
                  <div className='col-lg-8'>
                    <IconPicker value={icon} onChange={(v) => setIcon(v)} />
                  </div>
                </div>
                <div className='mb-3'>
                  <label>{t('Title')}</label>
                  <div className='mb-2'>
                    <input type="text" className="form-control"
                      {...register("title", { required: `${t('Field is required')}` })}
                    />
                    <div className="text-danger">{errors?.title?.message}</div>
                  </div>
                </div>

                <div className='mb-3'>
                  <label>{t('Description')}</label>
                  <textarea className="form-control" rows={3}
                    {...register("description")}
                  />
                </div>

                <div className='mb-3'>
                  <label>{t('Slug')}</label>
                  <div className='mb-2'>
                    <input type="text" className="form-control"
                      onKeyPress={(e) => {
                        const k = e ? e.which : window.event.keyCode;
                        if (k === 32) {
                          e.preventDefault()
                        }
                      }}
                      {...register("slug", { required: `${t('Field is required')}` })}
                    />
                    <div className="text-danger">{errors?.slug?.message}</div>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {t('Close')}
                </Button>
                <Button variant="primary" type="submit">
                  {t('Submit')}
                </Button>
              </Modal.Footer>
            </form>
          </div>
      }
    </Modal>
  );
}

export default ServiceModal;