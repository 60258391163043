import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation';
import { useForm } from "react-hook-form";
import Loading from '../../Layouts/Loading';
import invoiceCommentService from '../../../actions/invoiceCommentService';

const PageContainer = () => {
  const { t } = useTranslation();
  const { handleSubmit, setValue, register } = useForm({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState({})

  const getInvoiceComment = async () => {
    setLoading(true)
    const res = await invoiceCommentService.getInvoiceComment();
    setValue('bank_text', res?.result?.find(item => item.name === 'bank')?.text)
    setValue('stripe_text', res?.result?.find(item => item.name === 'stripe')?.text)
    setValue('paypal_text', res?.result?.find(item => item.name === 'paypal')?.text)
    setValue('payrexx_text', res?.result?.find(item => item.name === 'payrexx')?.text)
    setLoading(false)
  }

  useEffect(() => {
    getInvoiceComment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    const res = await invoiceCommentService.updateInvoiceComment(data);
    setResponse(res)
    setLoading(false)
  }

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body">
          <div className='card-title mb-4'><h4>{t('Invoice Design')}</h4></div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              !response.success && response.result &&
              <div className='alert alert-danger d-flex align-items-center' role='alert'>
                {t(response.result)}
              </div>
            }
            {
              response.success && response.result &&
              <div className='alert alert-success d-flex align-items-center' role='alert'>
                {t('Changing is successed')}
              </div>
            }
            <div className='row mb-3'>
              <label className='col-lg-2 mobile-left'>
                {t('Bank Transfer')}:
              </label>
              <div className='col-lg-9'>
                <textarea className='form-control' rows="3"
                  {...register("bank_text")}
                ></textarea>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-2 mobile-left'>
                {t('Stripe')}:
              </label>
              <div className='col-lg-9'>
                <textarea className='form-control' rows="3"
                  {...register("stripe_text")}
                ></textarea>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-2 mobile-left'>
                {t('Paypal')}:
              </label>
              <div className='col-lg-9'>
                <textarea className='form-control' rows="3"
                  {...register("paypal_text")}
                ></textarea>
              </div>
            </div>
            <div className='row mb-3'>
              <label className='col-lg-2 mobile-left'>
                {t('Payrexx')}:
              </label>
              <div className='col-lg-9'>
                <textarea className='form-control' rows="3"
                  {...register("payrexx_text")}
                ></textarea>
              </div>
            </div>

            <div className='row mt-3 mb-3'>
              <label className='col-lg-2'>
              </label>
              <div className='col-lg-9'>
                <input type="submit" className='btn btn-primary' value={t('Submit')} />
              </div>
            </div>

          </form>
        </div>
      </div>
  );
}

export default PageContainer;