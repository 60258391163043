import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getSteps = async () => {
  try {
    const response = await axios.get(backendUrl + '/api/get_steps/'+currentLng);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const updateStep = async (data) => {
  try {
    const response = await axios.post(backendUrl + '/api/update_step/'+currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const stepService = {
  getSteps,
  updateStep
}

export default stepService