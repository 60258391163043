import axios from 'axios'
import { backendUrl } from '../constants';

const currentLng = localStorage.getItem('i18nextLng') || 'de';

const getAllExpertises = async () => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  try {
    const response = await axios.get(backendUrl + '/api/get_all_expertises/'+currentLng, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const deleteExpertise = async (data) => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  data.token = token
  try {
    const response = await axios.post(backendUrl + '/api/delete_expertise', data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const upadateExpertise = async (data) => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  data.token = token
  try {
    const response = await axios.post(backendUrl + '/api/update_expertise/'+currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const createExpertise = async (data) => {
  const token = JSON.parse(localStorage.getItem('admin')).token;
  data.token = token
  try {
    const response = await axios.post(backendUrl + '/api/create_expertise/'+currentLng, data);
    return response.data
  } catch (err) {
    if (err.response?.data?.result) {
      return err.response.data
    } else {
      return {
        success: false,
        result: err.message
      };
    }
  }
}

const expertiseService = {
  getAllExpertises,
  deleteExpertise,
  upadateExpertise,
  createExpertise
}

export default expertiseService