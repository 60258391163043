import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import DataTable from "react-data-table-component";
import countryService from '../../../actions/countryService';
import Loading from '../../Layouts/Loading';
import CountryModal from './CountryModal';

const PageContainer = () => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('');
  const [country, setCountry] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [response, setResponse] = useState({})
  const [search, setSearch] = useState('');
  const [data, setData] = useState([])

  const getCountries = async () => {
    setLoading(true)
    const res = await countryService.getAllCountries();
    if (res.success && res.result) setCountries(res.result);
    setLoading(false)
  }

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteCountry = async (id) => {
    setLoading(true)
    const res = await countryService.deleteCountry({ id });
    if (res.success && res.result) {
      const newData = countries.filter((item) => item.id !== id);
      setCountries(newData)
    }
    setResponse(res)
    setLoading(false)
  }

  useEffect(() => {
    const result = countries.filter((item) => {
      if (search === '') return item;
      return item?.name?.toLowerCase().includes(search?.toLowerCase())
    })
    setData(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, search])

  const handleCloseModal = () => {
    setIsModal(false);
  }

  const addCountry = () => {
    setCountry({})
    setTitle('Add New Country')
    setIsModal(true);
  }

  const editCountry = (id) => {
    const found = data.find(obj => obj.id === id);
    setCountry(found)
    setTitle('Edit Country')
    setIsModal(true);
  }

  const handleUpdate = (data) => {
    let _countries = [...countries]
    const found = countries.find(obj => obj.id === data.id);
    if(found){
      _countries = _countries.map(country => {
        if (country.id === data.id) {
          return data
        }
        return country
      })
      setCountries(_countries)
    } else {
      _countries.push(data)
      setCountries(_countries)
      setResponse({success: true, result: 'added' });
      setIsModal(false);
    }
  }

  const columns = [
    {
      name: t('Name'),
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: '',
      cell: row => (
        <div className='text-end w-100'>
          <button className='btn btn-primary me-1 btn-sm' onClick={() => editCountry(row.id)}><i className="fa fa-pencil-square-o"></i></button>
          <button className='btn btn-danger btn-sm' onClick={() => deleteCountry(row.id)}><i className="fa fa-trash-o"></i></button>
        </div>
      )
    }
  ];

  return (
    loading
      ? <Loading />
      :
      <div className="card mb-3">
        <div className="card-body datableBody">
          <div className='card-title mb-4'><h4>{t('All Countries')}</h4></div>
          <div className='row'>
            <div className='col-lg-3'></div>
            <div className='col-lg-6'>
              {
                !response.success && response.result &&
                <div className='alert alert-danger d-flex align-items-center' role='alert'>
                  {t(response.result)}
                </div>
              }
              {
                response.success && response.result &&
                <div className='alert alert-success d-flex align-items-center' role='alert'>
                  {  response.result ==='added' ? t('Submit is successed') : t('Deleting is successed!')}
                </div>
              }

              <DataTable
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                subHeader
                noHeader={true}
                subHeaderComponent={
                  <div className='w-100 d-flex justify-content-between'>
                    <button className='btn btn-primary btn-sm' onClick={() => addCountry()}>{t('Add Country')}</button>
                    <input type="text" placeholder={t('search here')} className='w-25 form-control'
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {isModal &&
          <CountryModal
            isModal={isModal}
            handleCloseModal={handleCloseModal}
            title={title}
            country={country}
            handleUpdate = {handleUpdate}
          />
        }

      </div>

  )
}

export default PageContainer;