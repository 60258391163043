import React from "react";
import { menu } from '../../menu.json' 
import { useTranslation } from "../../hooks/useTranslation";

const LayoutLPageHeader = (props) => {
  const { activekey } = props
  const {t} = useTranslation();
  let Title;

  menu.forEach((d) => {
    if(d.children.length>0){
      d.children.forEach((sub1) => {
        if(sub1.children.length>0){
          sub1.children.forEach((sub2)=>{
            if('/'+sub2.routerLink === activekey) Title=sub2.name;
          })
        } else {
          if('/'+sub1.routerLink === activekey) Title=sub1.name;
        }
      })
    } else {
      if('/'+d.routerLink === activekey) Title=d.name;
    }
  });

  return (
    <div className={`body-header d-flex border-top py-3`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-auto">
            <h1 className="fs-4 mt-1 mb-0">{t(Title)}</h1>
            <small className="text-muted">You have 12 new messages and 7 new notifications.</small>
          </div>
          <div className="col d-flex justify-content-lg-end mt-2 mt-md-0">
            <div className="p-2 me-md-3">
              <div><span className="h6 mb-0">8.18K</span> <small className="text-success"><i className="fa fa-angle-up"></i> 4.33%</small></div>
              <small className="text-muted text-uppercase">Income</small>
            </div>
            <div className="p-2 me-md-3">
              <div><span className="h6 mb-0">1.11K</span> <small className="text-success"><i className="fa fa-angle-up"></i> 4.33%</small></div>
              <small className="text-muted text-uppercase">Expense</small>
            </div>
            <div className="p-2 pe-lg-0">
              <div><span className="h6 mb-0">3.66K</span> <small className="text-danger"><i className="fa fa-angle-down"></i> 4.33%</small></div>
              <small className="text-muted text-uppercase">Revenue</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LayoutLPageHeader;