import React, { useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import CountryDropdown from '../Layouts/common/CountryDropdown'
import noUser from '../../assets/images/user_black.jpg'
import authService from '../../actions/authService';
import Loading from '../Layouts/Loading';
import { backendUrl } from '../../constants';
import { getCoordinates } from '../../hooks/googleMap';

import "react-datepicker/dist/react-datepicker.css";

const BasicInformation = (props) => {
  const { user } = props
  const { t } = useTranslation();
  const [image, setImage] = useState(user?.photo ? backendUrl+'/images/profile/'+user.photo + '?v='+ new Date().getTime() : '');
  const [selectedFile, setSelectedFile] = useState()
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({})
  const { auth } = useSelector(store => store.auth)
  const { configs } = useSelector(store => store.configs)

  const { handleSubmit, setValue, register, control, formState: { errors } } = useForm({
    defaultValues: {
      first_name: user?.first_name || '' ,
      last_name: user?.last_name || '',
      birthday: user?.birthday ? new Date(user?.birthday) : '',
      phone: user?.phone || '',
      gender: user?.gender || '',
      email: user?.email || '',
      zip: user?.zip || '',
      country_id: user?.country_id || '',
      state: user?.state || '',
      city: user?.city || '',
      street: user?.street || '',
      about_me: user?.about_me || '',
    }
  });

  const onImageChange = (e) => {
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    setSelectedFile(file)
    setValue('photo', file.name);
  };

  const removePhoto = (e) => {
    e.preventDefault();
    document.getElementById("file").value = "";
    setImage('');
    setSelectedFile('')
    setValue('photo', '');
  }

  const submitData = async (data) => {
    setLoading(true)
    let res;
    if(user){
      res = await authService.userUpdate(data);
    } else {
      res = await authService.userCreate(data);
    }

    setResponse(res)
    setLoading(false)
  }

  const getCood = async (address) => {
    try {
      return await getCoordinates(address, configs.google_map_api)
    } catch(e) {
      return {lat: 0, lng: 0}
    }
  }

  const onSubmit = async (data) => {
    data.birthday = data?.birthday ? moment(data?.birthday).format('yyyy-MM-DD') : '';
    const formData = new FormData();
    formData.append('token', auth.token);
    formData.append('uploadFile', selectedFile);
    const entries = Object.entries(data);
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });

    const res = await getCood(data?.city + ', '+ data?.zip);
    formData.append('lat', res?.lat || 0);
    formData.append('lng', res?.lng || 0);
    if(user){
      formData.append('id', user.id);
    }
    submitData(formData);
  }

  return (
    loading
      ? <Loading />
      :
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          !response.success && response.result &&
          <div className='alert alert-danger d-flex align-items-center' role='alert'>
            {t(response.result)}
          </div>
        }
        {
          response.success && response.result &&
          <div className='alert alert-success d-flex align-items-center' role='alert'>
            {t('Patient is saved successfully')}
          </div>

        }
        <div className="row g-4">
          <div className="col-sm-4">
            <label className="form-label">{t('First Name')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              {...register("first_name", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.first_name?.message}</div>
          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Last Name')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              {...register("last_name", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.last_name?.message}</div>
          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Birthday')}</label>
            <Controller
              name='birthday'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <DatePicker className="form-control form-control-lg"
                  selected={value}
                  onChange={onChange}
                  dateFormat="dd-MM-yyyy"
                />
              )}
            />

          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Phone')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              onKeyPress={(e) => {
                if (!/^[0-9.]$/.test(e.key)) {
                  e.preventDefault()
                }
              }}
              {...register("phone")}
            />
          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Gender')}</label>
            <select className="form-control form-control-lg"
              {...register("gender", { required: `${t('Field is required')}` })}
            >
              <option value="male">{t('Male')}</option>
              <option value="female">{t('Female')}</option>
            </select>
            <div className="text-danger">{errors?.gender?.message}</div>
          </div>
          <div className='col-sm-4'>
            <label className="form-label">{t('Country')}</label>
            <Controller
              name='country_id'
              control={control}
              render={({
                field: { value, onChange }
              }) => (
                <CountryDropdown
                  selected={value}
                  onChange={onChange}
                />
              )}
              rules={{ required: t('Field is required') }}
            />
            <div className="text-danger">{errors?.country_id?.message}</div>
          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Email address')}</label>
            <input type="email" className="form-control form-control-lg" placeholder={t('Enter here')} autoComplete="off"
              {...register("email", { required: `${t('Field is required')}` })}
            />
            <div className="text-danger">{errors?.email?.message}</div>
          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Password')}</label>
            <input type="password" className="form-control form-control-lg" placeholder={t('Enter here')} autoComplete="new-password"
              {...register("password", {
                required: user ? false : `${t('Field is required')}`,
                minLength: {
                  value: 6,
                  message: `${t('Password-charactors')}`
                }
              })}
            />
            <div className="text-danger">{errors?.password?.message}</div>
          </div>
          <div className="col-sm-4">
            <label className="form-label">{t('Post code')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              {...register("zip", { required: `${t('Field is required')}` })}
            />
          </div>
          <div className='col-sm-4'>
            <label className="form-label">{t('State')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              {...register("state")}
            />
          </div>
          <div className='col-sm-4'>
            <label className="form-label">{t('City')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              {...register("city")}
            />
          </div>
          <div className='col-sm-4'>
            <label className="form-label">{t('Street No.')}</label>
            <input type="text" className="form-control form-control-lg" placeholder={t('Enter here')}
              {...register("street")}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="file" className='cursor-pointer d-block text-center'>
              <img src={image ? image : noUser} alt={t('Profile')} style={{ width: '100%', maxWidth: '100px' }} className="mb-2" />
              <br />
              <button className='btn btn-danger btn-sm' onClick={removePhoto}><i className="fa fa-trash-o"></i></button>
            </label>
            <input type="file" className="form-control d-none" id="file" onChange={onImageChange} accept="image/png, image/gif, image/jpeg" />
          </div>
          <div className="col-md-10">
            <textarea rows="6" className="form-control no-resize" placeholder={t('Please type about...')}
              {...register("about_me")}
            ></textarea>
          </div>
          <div className="col-sm-12">
            <input type="hidden" value="patient"
              {...register("role")}
            />
            <button type="submit" className="btn btn-primary me-2">{t('Submit')}</button>
          </div>
        </div>
      </form>
  );
}

export default BasicInformation;