import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import Propfileimage from "../../../assets/images/profile_av.png";
import { userLogout } from '../../../reducers/auth'
import { useTranslation } from "../../../hooks/useTranslation";
import { backendUrl } from "../../../constants";

const UserProfileDropdown = (props) => {
  const { DropdownClass, as, DropdownMenuClass, DropdownToggleClass, DropMenuTag } = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { auth } = useSelector(store => store.auth)
  
  const handleLogout = () => {
    dispatch(userLogout(auth.token))
  }

  return (
    <Dropdown as={as ? as : "div"} className={DropdownClass ? DropdownClass : "user-profile mx-lg-3 mx-1 "} drop="down">
      <Dropdown.Toggle className={DropdownToggleClass ? DropdownToggleClass : "nav-link dropdown-toggle pulse p-0"} as="a" data-toggle="dropdown">
        <img className="avatar rounded-circle img-thumbnail" src={auth?.photo ? backendUrl+'/images/profile/'+auth.photo + '?v='+ new Date().getTime() : Propfileimage} alt="" />
      </Dropdown.Toggle >
      <Dropdown.Menu as={DropMenuTag ? DropMenuTag : "ul"} className={DropdownMenuClass ? DropdownMenuClass : ""}>
        <div className="card border-0 w240">
          <div className="card-body border-bottom">
            <div className="d-flex py-1">
              <img className="avatar rounded-circle" src={auth?.photo ? backendUrl+'/images/profile/'+auth.photo + '?v='+ new Date().getTime() : Propfileimage} alt="" />
              <div className="flex-fill ms-3">
                <p className="mb-0 text-muted"><span className="font-weight-bold">{auth?.first_name} {auth?.last_name}</span></p>
                <small className="text-muted">{auth?.email}</small>
                <div>
                  <a href="#!" onClick={handleLogout} className="card-link">{t('Sign out')}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="list-group m-2">
            <Link to="/profilepage" className="list-group-item list-group-item-action border-0"><i className="w30 fa fa-user"></i>{`${t('Profile')} & ${t('Account')}`}</Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserProfileDropdown;